// React
import { useState } from "react"

// Material UI
import {
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
} from "@mui/material"

interface Props {
    label: string
    defaultDeadline: number
    // eslint-disable-next-line no-unused-vars
    onChange: (deadline: string) => void
    isRequired: boolean
}

const DeadlineInput = ({ label, defaultDeadline, onChange, isRequired }: Props) => {
    const [deadline, setDeadline] = useState(defaultDeadline)
    const [otherDeadline, setOtherDeadline] = useState("")

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeadline(parseInt(event.target.value))
        if (parseInt(event.target.value) === 0) {
            // Selected other
            onChange(otherDeadline)
        } else {
            onChange(parseInt(event.target.value).toString())
        }
    }

    const handleOtherTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherDeadline(event.target.value)
        if (deadline === 0) {
            onChange(event.target.value)
        }
    }

    return (
        <FormControl fullWidth>
            <FormLabel id="radio-buttons-group-label" required={isRequired}>
                {label}
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={handleRadioChange}
            >
                <FormControlLabel value={7} control={<Radio />} label="7 days" />
                <FormControlLabel value={14} control={<Radio />} label="14 days" />
                <FormControlLabel value={21} control={<Radio />} label="21 days" />
                <FormControlLabel value={0} control={<Radio />} label="Other" />
            </RadioGroup>
            {deadline === 0 && (
                <TextField
                    sx={{ mt: 1, mb: 1 }}
                    id="outlined-basic"
                    label="Some other number of days"
                    variant="outlined"
                    onChange={handleOtherTextChange}
                />
            )}
        </FormControl>
    )
}

export default DeadlineInput

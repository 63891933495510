// React
import React, { useState } from "react"

// Material UI
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"

// Contexts
import { useAuth } from "../context/AuthContext"

export default function OrganisationSelect() {
    const auth = useAuth()
    // eslint-disable-next-line no-unused-vars
    const [selectedTenantId, setSelectedTenantId] = useState<string>()

    const handleChange = (event: SelectChangeEvent) => {
        const tenantId = event.target.value
        setSelectedTenantId(tenantId)
        auth.changeTenant(tenantId)
    }

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="organisation-select-small-label">Organisation</InputLabel>
            <Select
                labelId="organisation-select-small-label"
                id="organisation-select-small"
                value={auth.activeTenant.tenantId}
                label="Organisation"
                onChange={handleChange}
            >
                {auth.tenants.map((org) => {
                    return (
                        <MenuItem key={org.tenantId} value={org.tenantId}>
                            {org.tenantName}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

import { Phone } from "xero-node/dist/gen/model/accounting/phone"

const formattedPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length % 3 === 0) {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3")
    } else if (phoneNumber.length % 4 === 0) {
        return phoneNumber.replace(/(\d{4})(\d{4})/, "$1 $2")
    } else if (phoneNumber.length % 3 === 1) {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3")
    } else if (phoneNumber.length % 3 === 2) {
        return phoneNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4")
    } else {
        return phoneNumber
    }
}

export const getFormattedStringFromPhone: (phone: Phone) => string | null = (phone: Phone) => {
    const phoneNumber = phone.phoneNumber
    const countryCode = phone.phoneCountryCode
    const areaCode = phone.phoneAreaCode

    if (phoneNumber && countryCode && areaCode) {
        return `+${countryCode} (${areaCode}) ${formattedPhoneNumber(phoneNumber)}`
    } else if (countryCode && phoneNumber) {
        return `+${countryCode} ${formattedPhoneNumber(phoneNumber)}`
    } else if (phoneNumber && areaCode) {
        return `(${areaCode}) ${formattedPhoneNumber(phoneNumber)}`
    } else if (phoneNumber) {
        return formattedPhoneNumber(phoneNumber)
    }
    return null
}

import { Collapse, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Alert } from "@mui/material"

import { useEffect, useState } from "react"

const DISCLAIMER_ALERT_DISMISSED = "disclaimerAlertDismissed"

export default function DismissibleDisclaimer() {
    const [showDisclaimerAlert, setShowDisclaimerAlert] = useState(false)

    useEffect(() => {
        const utcDismissDateString = localStorage.getItem(DISCLAIMER_ALERT_DISMISSED)

        console.log("utcDismissDateString", utcDismissDateString)

        if (utcDismissDateString === null || utcDismissDateString === undefined) {
            return setShowDisclaimerAlert(true)
        }

        const disclaimerAlertDismissedDate = new Date(utcDismissDateString)

        if (disclaimerAlertDismissedDate > new Date(Date.now() - 1000 * 60 * 60 * 24 * 7)) {
            setShowDisclaimerAlert(false)
        } else {
            setShowDisclaimerAlert(true)
        }
    }, [])

    const handleDismiss = () => {
        localStorage.setItem(DISCLAIMER_ALERT_DISMISSED, new Date().toUTCString())
        setShowDisclaimerAlert(false)
    }

    return (
        <Collapse in={showDisclaimerAlert}>
            <Alert
                sx={{ mt: 2 }}
                severity="info"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleDismiss}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                Law off the Shelf is not a law firm and does not provide legal advice. The documents
                and information published by Law off the Shelf are general and should not to be
                considered legal advice. If you require legal advice, you should speak to a lawyer.
                Use of Law Off the Shelf is subject to our Terms and Conditions and Privacy Policy.
            </Alert>
        </Collapse>
    )
}

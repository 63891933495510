// React
import { useState, useEffect } from "react"

// Material UI
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { FormHelperText } from "@mui/material"

// Components
import AddressSelect from "./AddressSelect"

// Types
import { Address, Contact, Invoice } from "xero-node"

// Contexts
import { useAuth } from "../context/AuthContext"

// Utils
import { daysBetween, formatDateString } from "../lib/DateUtils"
import { moneyFormatter } from "../lib/MoneyUtils"

const API_KEY = process.env.REACT_APP_API_KEY

interface Props {
    defaultInvoiceID: string | null
    // eslint-disable-next-line no-unused-vars
    onChange: (invoice: Invoice | null) => void
    isRequired: boolean
}

const XeroOverdueInvoiceSelect = ({ defaultInvoiceID, onChange, isRequired }: Props) => {
    const auth = useAuth()
    const [loading, setLoading] = useState(true)
    const [invoices, setInvoices] = useState<Invoice[]>([])
    const [addresses, setAddresses] = useState<Address[] | null>(null)
    const [selectedInvoiceData, setSelectedInvoiceData] = useState<Invoice | null>(null)

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            onChange(null)
            const resp = await fetch("/api/invoices/overdue", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Api-Key ${API_KEY}`,
                },
            })
            if (resp.status === 200) {
                const response = await resp.json()
                // TODO: Map invoices to a hash table for faster lookup and create an array of
                // ordered invoice ids for the select component.
                setInvoices(response.invoices)
            } else {
                setInvoices([])
            }
            setLoading(false)
        })()
    }, [auth.loading, auth.activeTenant]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleInvoiceChange = async (event: SelectChangeEvent) => {
        const invoiceData = invoices.find((i) => i.invoiceID === event.target.value)

        if (invoiceData) {
            if (invoiceData?.contact?.contactID !== null) {
                // Fetch detailed contact information by invoice.
                const contactResponse = await fetch(
                    `/api/contacts/${invoiceData?.contact?.contactID}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Api-Key ${API_KEY}`,
                        },
                    },
                )

                if (contactResponse.ok) {
                    invoiceData.contact = (await contactResponse.json()) as Contact
                } else {
                    // TODO: Handle error
                }
            }
        }

        // Set addresses from contact addresses
        if (invoiceData?.contact?.addresses?.length) {
            setAddresses(invoiceData.contact.addresses)
        }

        setSelectedInvoiceData(invoiceData || null)
        onChange(invoiceData || null)
    }

    // Handle address select change
    const handleAddressChange = (selectedAddress: Address | null) => {
        if (selectedAddress) {
            const addresses = [selectedAddress]
            const selectedInvoiceDataCopy = { ...selectedInvoiceData }
            if (selectedInvoiceDataCopy?.contact) {
                selectedInvoiceDataCopy.contact.addresses = addresses
            }
            onChange(selectedInvoiceDataCopy)
        }
    }

    return (
        <Box sx={{ minWidth: 120, maxWidth: 500 }}>
            <FormControl fullWidth>
                <InputLabel id="overdue-invoice-select-label" required={isRequired}>
                    Invoice
                </InputLabel>
                <Select
                    labelId="overdue-invoice-select-label"
                    id="overdue-invoice-select"
                    label="Invoice"
                    defaultValue={defaultInvoiceID ?? ""}
                    onChange={handleInvoiceChange}
                >
                    {loading ? (
                        <MenuItem value="0">Loading...</MenuItem>
                    ) : invoices.length > 0 ? (
                        invoices.map((i: Invoice) => (
                            <MenuItem key={i.invoiceID} value={i.invoiceID}>
                                #{i.invoiceNumber} - {i.contact?.name} -{" "}
                                {moneyFormatter.format(i.amountDue ?? 0)} - due on{" "}
                                {formatDateString(i.dueDate)},{" "}
                                {daysBetween(new Date(), new Date(i.dueDate ?? "")).toString()} days
                                overdue
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="0">No overdue invoices found</MenuItem>
                    )}
                </Select>
                <FormHelperText>Select an overdue invoice</FormHelperText>
            </FormControl>

            <Box sx={{ mt: 2 }}>
                <AddressSelect
                    addresses={addresses}
                    onChange={handleAddressChange}
                    isRequired={true}
                />
            </Box>
        </Box>
    )
}

export default XeroOverdueInvoiceSelect

// React
import { useEffect, useState } from "react"

// Components
import DocumentRow from "./DocumentRow"
import DocumentDeliveryFormDialog from "./DocumentDeliveryFormDialog"
import MenuBar from "./MenuBar"
import Footer from "./Footer"

// Styles
import styles from "../styles/Home.module.css"

// Material UI
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material"
import { Box, Container } from "@mui/system"

// Types
import { Document } from "../types/Document"

// Context
import { useAuth } from "../context/AuthContext"

// Utils
import { useSnackbar } from "notistack"

const API_KEY = process.env.REACT_APP_API_KEY

const MyDocuments = () => {
    const auth = useAuth()
    const { enqueueSnackbar } = useSnackbar()
    const [documents, setDocuments] = useState<Document[]>([])
    const [loading, setLoading] = useState(false)

    const [openFormDialog, setOpenFormDialog] = useState(false)
    const [selectedDocument, setSelectedDocument] = useState<Document | null>(null)
    const handleOpenFormDialog = () => setOpenFormDialog(true)
    const handleCloseFormDialog = () => setOpenFormDialog(false)

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            const resp = await fetch(`/api/documents`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Api-Key ${API_KEY}`,
                },
            })
            if (resp.status === 200) {
                try {
                    const documents = await resp.json() as Document[]
                    setDocuments(documents)
                    setLoading(false)
                } catch (error) {
                    setDocuments([])
                    setLoading(false)
                    console.log(error)
                    // Show a snackbar with error message
                    enqueueSnackbar("We're unable to fetch your documents at this time", { variant: "error" })
                }
            }
        })()
    }, [auth.loading, auth.activeTenant.tenantId, enqueueSnackbar])

    return (
        <div className={styles.container}>
            <MenuBar />
                <DocumentDeliveryFormDialog
                    document={selectedDocument}
                    open={openFormDialog}
                    onClose={handleCloseFormDialog}
                />

                <Container sx={{ pt: 12 }} maxWidth="lg">
                    <Box sx={{ minHeight: "100vh" }}>
                        <Typography variant="h4">My documents</Typography>
                        <TableContainer sx={{ mt: 4, mb: 2 }} component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Recipient</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading && (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                Loading...
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {!loading && documents.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                No documents found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {!loading && documents.length > 0 && documents.map((document, index) => (
                                        <DocumentRow
                                            key={document.id}
                                            index={index}
                                            document={document}
                                            onDocumentDelivery={() => {
                                                setSelectedDocument(document)
                                                handleOpenFormDialog()
                                            }}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Container>
            <Footer />
        </div>
    )
}

export default MyDocuments

import { Group } from "../types/Group"

export const templateGroups: Group[] = [
    {
        name: "Recover a debt",
        templates: [
            {
                id: "letter-of-demand",
                templateId: "1voz_7mkmdKMWdn9UJGWjAWDmyBPp5L2tXJsavnorw5o",
                name: "Letter of Demand",
                summary:
                    "If you’ve followed up an unpaid invoice a few times and need to escalate the issue, send a Letter of Demand as a first step",
                pdfPreviewUrl: "./previews/letter-of-demand.pdf",
                isComingSoon: false,
                isNSWOnly: false,
                faqs: [
                    {
                        question: "What is a Letter of Demand?",
                        answer: "A Letter of Demand seeks payment of an unpaid invoice within a specific timeframe",
                    },
                    {
                        question: "When is a Letter of Demand used?",
                        answer: "A Letter of Demand is used after an invoice has been issued and remains unpaid after the payment period",
                    },
                    {
                        question: "Who is a Letter of Demand intended for?",
                        answer: "A Letter of Demand can be sent to a business or person who hasn’t paid an overdue unpaid invoice",
                    },
                    {
                        question: "How is a Letter of Demand signed?",
                        answer: "A Letter of Demand is signed electronically by an authorised representative of the business seeking payment; this could be the business owner, their accountant or someone in the accounts department",
                    },
                    {
                        question: "What happens next?",
                        answer: "Hopefully the invoice is paid. If not, the business seeking payment can send the Pre-litigation Letter of Demand",
                    },
                ],
            },
            {
                id: "pre-litigation-letter-of-demand",
                templateId: "1-etcU6KsGMHMVux82eouAYCydQlWB_fyZ6F-sjiTZAM",
                name: "Pre-litigation Letter of Demand",
                summary:
                    "If you’ve followed up an unpaid invoice many times either directly or by sending the Letter of Demand, and the debt remains unpaid, you can escalate the matter by sending the Pre-litigation Letter of Demand",
                pdfPreviewUrl: "./previews/pre-litigation-letter.pdf",
                isComingSoon: false,
                isNSWOnly: false,
                faqs: [
                    {
                        question: "What is a Pre-litigation Letter of Demand?",
                        answer: "A Pre-litigation Letter of Demand is a further letter demanding payment of an unpaid invoice within a specific timeframe, noting that if the invoice is not paid, further legal action may be taken",
                    },
                    {
                        question: "When is a Pre-litigation Letter of Demand used?",
                        answer: "Once a Letter of Demand has been ignored, this letter could be sent prior to commencing any other legal action",
                    },
                    {
                        question: "Who is a Pre-litigation Letter of Demand intended for?",
                        answer: "A Pre-litigation Letter of Demand business can be sent to a business or person who hasn’t paid an overdue unpaid invoice and a previous attempt has been made to demand payment",
                    },
                    {
                        question: "How is a Pre-litigation Letter of Demand signed?",
                        answer: "Electronically by an authorised representative of the business seeking payment; this could be the business owner, their accountant or someone in the accounts department",
                    },
                    {
                        question: "What happens next?",
                        answer: "Hopefully the invoice is paid. If not, and the business has exhausted all other options, it could consider filing a statement of claim in the Local Court.",
                    },
                ],
            },
            {
                id: "statement-of-claim",
                templateId: "1Q600VCVuuNcRZqdgRjewXIyNO_49ucc-3fegVA3vVjI",
                name: "Statement of Claim",
                summary:
                    "If you have sent the Letter of Demand and the Pre-litigation Letter of Demand and the debt still remains unpaid without a reasonable explanation, you can escalate matters by filing a Statement of Claim in the Local Court",
                pdfPreviewUrl: "./previews/statement-of-claim.pdf",
                isComingSoon: false,
                isNSWOnly: true,
                faqs: [
                    {
                        question: "What is a Statement of Claim?",
                        answer: "A Statement of Claim is a court document that seeks repayment of the unpaid invoice",
                    },
                    {
                        question: "When is a Statement of Claim used?	",
                        answer: "If demands have been made but the invoice remains unpaid, the business can file a Statement of Claim  to obtain a judgment for the unpaid invoice amount",
                    },
                    {
                        question: "Who is a Statement of Claim intended for?",
                        answer: "A Statement of Claim can be used by any business or person who wishes to pursue recovery by commencing legal action in the Local Court",
                    },
                    {
                        question: "How is a Statement of Claim signed?",
                        answer: "A Statement of Claim should be signed in person by the business owner. If the business is a company, a director of the company will sign. If a sole trader, the sole trader will sign",
                    },
                    {
                        question: "What happens next?",
                        answer: "The Statement of Claim  should be printed in triplicate and signed.  It will then need to be filed in the Local Court. If judgment for the invoice amount is granted, you will then need to enforce the judgment to recover the money.",
                    },
                ],
            },
            {
                id: "settlement-and-release-agreement",
                templateId: "1zb8O1_s806phmcY38XzrFiRS8uS0itCKfFkcm-Y6Kmw",
                name: "Settlement & Release Agreement",
                summary:
                    "If you have reached an agreement for payment of an invoice and the parties wish to record the terms, you can set this out in a Settlement and Release Agreement",
                pdfPreviewUrl: "./previews/settlement-and-release-agreement.pdf",
                isComingSoon: false,
                isNSWOnly: false,
                faqs: [
                    {
                        question: "What is a Settlement and release agreement?",
                        answer: "A Loan Agreement is used by parties to set out the terms of an unsecured loan including the amount lent, interest charges and repayment",
                    },
                    {
                        question: "When is a Settlement and release agreement used?",
                        answer: "A Settlement and Release Agreement is used where a settlement is reached and both parties which to record that agreement and release each other from further claims",
                    },
                    {
                        question: "Who is a Settlement and release agreement intended for?",
                        answer: "A Settlement and Release Agreement can be used by parties who which to record the terms of their settlement and include a mutual release in regards to the invoice or subject matter",
                    },
                    {
                        question: "How is a Settlement and release agreement signed?",
                        answer: "A Settlement and Release Agreement can be signed electronically by the parties or their authorised representative",
                    },
                    {
                        question: "What happens next?",
                        answer: "Once the Settlement and Release Agreement is signed by both parties and any settlement amount is paid, the matter is settled and the Settlement and Release Agreement comes into effect",
                    },
                ],
            },
        ],
    },
    {
        name: "Create a loan",
        templates: [
            {
                id: "loan-agreement",
                templateId: "18RsQun5XFNhv-Ljk5l9mfJcy2qNSXlX1pd00ak2drIo",
                name: "Loan Agreement",
                summary:
                    "If you’d like to loan money to a person or business, you can record the terms of that loan, including the amount lent and any interest charged, in a Loan Agreement",
                pdfPreviewUrl: "./previews/loan-agreement.pdf",
                isComingSoon: false,
                isNSWOnly: false,
                faqs: [
                    {
                        question: "What is a Loan agreement?",
                        answer: "A Loan Agreement is used by parties to set out the terms of an unsecured loan including the amount lent, interest charges and repayment",
                    },
                    {
                        question: "When is a Loan agreement used?",
                        answer: "A Loan Agreement is used where there is an unsecured loan that is repayable on a specific date and the parties wish to record the loan in writing",
                    },
                    {
                        question: "Who is a Loan agreement intended for?",
                        answer: "A Loan Agreement can be used by a business or individual who is loaning money to a third party",
                    },
                    {
                        question: "How is a Loan agreement signed?",
                        answer: "A Loan Agreement is signed electronically by the parties or their authorised representatives",
                    },
                    {
                        question: "What happens next?",
                        answer: "After the Loan Agreement is signed by both parties, the money should be electronically transmitted at the direction of the borrower",
                    },
                ],
            },
            {
                id: "loan-agreement-with-security",
                templateId: "1UrCitAC6FJLA5qBHR32rKILNJtVhTJ25CO4rLg3z41U",
                name: "Division 7A Loan Agreement",
                summary: "If a company wishes to loan money to shareholders, or associates of those shareholders, they can use a Division 7A Loan Agreement to set out the terms",
                pdfPreviewUrl: "./previews/division-7a-loan-agreement.pdf",
                isComingSoon: false,
                isNSWOnly: false,
                faqs: [
                    {
                        question: "What is a Division 7A Loan Agreement?",
                        answer: "A Division 7A Loan Agreement is used to record the terms of a loan from a company, including a corporate trustee, to a shareholder or their associates",
                    },
                    {
                        question: "When is a Division 7A Loan Agreement used?	",
                        answer: "A Division 7A Loan Agreement is used where a company, including a corporate trustee, is loaning funds to a shareholder or their associates and they do not wish for the funds to be deemed dividends",
                    },
                    {
                        question: "Who is a Division 7A Loan Agreement intended for?",
                        answer: "A Division 7A Loan Agreement is used by a company and its shareholders or people or entities related to those shareholders",
                    },
                    {
                        question: "How is a Division 7A Loan Agreement signed?",
                        answer: "A Division 7A Loan Agreement is signed electronically by the parties to the Agreement or their authorised representatives",
                    },
                    {
                        question: "What happens next?",
                        answer: "After the Division 7A Loan Agreement is signed, the terms come into effect and the loan must be repaid within 7 years",
                    },
                ],
            },
            {
                id: "promissory-note",
                templateId: "1lHnluwwsk0EXble5lf8i5lJFvjfvYWiNEysO_SibKkw",
                name: "Promissory Note",
                summary:
                    "If you’d like to record a loan that is repayable on a specific date or the happening of a specific event, and there is a high degree of trust between the parties, you can use a Promissory Note",
                pdfPreviewUrl: "./previews/promissory-note.pdf",
                isComingSoon: false,
                isNSWOnly: false,
                faqs: [
                    {
                        question: "What is a Promissory note?",
                        answer: "A Promissory Note is an unconditional promise to repay an amount on a specific date or after a specific event",
                    },
                    {
                        question: "When is a Promissory note used?",
                        answer: "A Promissory Note is less formal than a Loan Agreement and suitable for smaller debts. If you want more extensive, specific terms, please consider the Loan Agreement",
                    },
                    {
                        question: "Who is a Promissory note intended for?",
                        answer: "For documenting small loans where the parties know and trust each other",
                    },
                    {
                        question: "How is a Promissory note signed?",
                        answer: "This document will be signed by both the lender and borrower, or where the lender and borrower are companies or individuals, an authorised representative",
                    },
                    {
                        question: "What happens next?",
                        answer: "Once signed by the borrower, the Promissory Note comes into effect",
                    },
                ],
            },
        ],
    },
]

// React
import { useState, useEffect, Fragment } from "react"

// Material UI
import {
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    FormControl,
    Divider,
    TextField,
    Drawer,
} from "@mui/material"
import { Box, Stack } from "@mui/system"
import { LoadingButton } from "@mui/lab"
import { Close } from "@mui/icons-material"

// Types
import { Document } from "../types/Document"

// Context
import { useAuth } from "../context/AuthContext"

// Utils
import { useSnackbar } from "notistack"

const API_KEY = process.env.REACT_APP_API_KEY

type DocumentDeliveryRequestBody = {
    sender: {
        name: string
        email: string
        mobile: string | null
    }
    recipient: {
        name: string
        email: string
        mobile: string | null
    }
}

interface Props {
    document: Document | null
    open: boolean
    onClose: () => void
}

// Function to validate mobile number format is e.164 i.e. +61XXXXXXXXXX
const validateMobileE164 = (mobile: string) => {
    const re = /^\+[1-9]\d{1,14}$/
    if (re.test(mobile)) {
        return true
    }
    return false
}

// Function to validate email address
const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/
    if (re.test(email)) {
        return true
    }
    return false
}

const DocumentDeliveryFormDialog = ({ document, open, onClose }: Props) => {
    // Context variables
    const auth = useAuth()
    const { enqueueSnackbar } = useSnackbar()

    // State variables
    const [documentDeliveryInProgress, setDocumentDeliveryInProgress] = useState(false)

    const [senderName, setSenderName] = useState("")
    const [senderEmail, setSenderEmail] = useState("")
    const [senderMobile, setSenderMobile] = useState<string | null>(null)

    const [recipientName, setRecipientName] = useState("")
    const [recipientEmail, setRecipientEmail] = useState("")
    const [recipientMobile, setRecipientMobile] = useState<string | null>(null)

    useEffect(() => {
        if (auth.activeTenant) {
            setSenderName(auth.user?.name || "")
            setSenderEmail(auth.user?.email || "")
        }

        // Reset all other state
        setSenderMobile(null)
        setRecipientName("")
        setRecipientEmail("")
        setRecipientMobile(null)
    }, [document, open, auth.activeTenant, auth.user])

    const handleSubmit = async () => {
        if (process.env.NODE_ENV === "development") {
            console.log("Document ID: ", document?.id)
        }

        if (document === null) {
            // Protection against an unlikely submit event where the document is null.
            return
        }

        if (
            senderName === "" ||
            senderEmail === "" ||
            recipientName === "" ||
            recipientEmail === ""
        ) {
            enqueueSnackbar("Please fill in all required fields.", { variant: "error" })
            return
        }

        if (!validateEmail(senderEmail)) {
            enqueueSnackbar("Please enter a valid sender email address.", { variant: "error" })
            return
        }

        if (!validateEmail(recipientEmail)) {
            enqueueSnackbar("Please enter a valid recipient email address.", {
                variant: "error",
            })
            return
        }

        if (senderMobile !== null && !validateMobileE164(senderMobile)) {
            enqueueSnackbar("Please enter a valid sender mobile number e.g. +6140000000", {
                variant: "error",
            })
            return
        }

        if (recipientMobile !== null && !validateMobileE164(recipientMobile)) {
            enqueueSnackbar("Please enter a valid recipient mobile number e.g. +6140000000", {
                variant: "error",
            })
            return
        }

        setDocumentDeliveryInProgress(true)

        const requestBody: DocumentDeliveryRequestBody = {
            sender: {
                name: senderName,
                email: senderEmail,
                mobile: senderMobile,
            },
            recipient: {
                name: recipientName,
                email: recipientEmail,
                mobile: recipientMobile,
            },
        }

        const resp = await fetch(`/api/documents/${document.id}/deliver`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Api-Key ${API_KEY}`,
            },
            body: JSON.stringify(requestBody),
        })

        // Document delivery request completed.
        setDocumentDeliveryInProgress(false)

        if (resp.status === 200) {
            enqueueSnackbar("Document delivered successfully.", { variant: "success" })
        } else {
            enqueueSnackbar("There was an error delivering your document", {
                variant: "warning",
                autoHideDuration: 10000,
            })
        }

        // Close the modal regardless of success or failure.
        onClose()
    }

    return (
        <Fragment>
            <Drawer anchor="right" open={open} onClose={onClose}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography sx={{ flexGrow: 1 }} variant="h6" color="inherit">
                            Deliver {document?.name}
                        </Typography>
                        <IconButton onClick={onClose} color="inherit">
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box p={4}>
                    <Box sx={{ m: 2 }}>
                        <Typography gutterBottom variant="h6">
                            Sender
                        </Typography>
                        <Stack spacing={4}>
                            <TextField
                                fullWidth
                                required
                                label="Name"
                                variant="outlined"
                                margin="normal"
                                defaultValue={senderName}
                                onChange={(event) => {
                                    setSenderName(event.target.value)
                                }}
                            />
                            <TextField
                                fullWidth
                                required
                                label="Email"
                                variant="outlined"
                                margin="normal"
                                defaultValue={senderEmail}
                                onChange={(event) => {
                                    setSenderEmail(event.target.value)
                                }}
                            />
                            <TextField
                                fullWidth
                                required={false}
                                label="Mobile"
                                variant="outlined"
                                margin="normal"
                                onChange={(event) => {
                                    setSenderMobile(event.target.value)
                                }}
                            />
                        </Stack>
                    </Box>
                    <Divider sx={{ my: 4 }} variant="middle" />
                    <Box sx={{ m: 2 }}>
                        <Typography gutterBottom variant="h6">
                            Recipient
                        </Typography>
                        <Stack spacing={4}>
                            <TextField
                                fullWidth
                                required
                                label="Name"
                                variant="outlined"
                                margin="normal"
                                onChange={(event) => {
                                    setRecipientName(event.target.value)
                                }}
                            />
                            <TextField
                                fullWidth
                                required
                                label="Email"
                                variant="outlined"
                                margin="normal"
                                onChange={(event) => {
                                    setRecipientEmail(event.target.value)
                                }}
                            />
                            <TextField
                                fullWidth
                                required={false}
                                label="Mobile"
                                variant="outlined"
                                margin="normal"
                                onChange={(event) => {
                                    setRecipientMobile(event.target.value)
                                }}
                            />
                        </Stack>
                    </Box>

                    <Box sx={{ m: 2 }}>
                        <FormControl>
                            <LoadingButton
                                sx={{ mt: 2 }}
                                loading={documentDeliveryInProgress}
                                disabled={documentDeliveryInProgress}
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Deliver document
                            </LoadingButton>
                        </FormControl>
                    </Box>
                </Box>
            </Drawer>
        </Fragment>
    )
}

export default DocumentDeliveryFormDialog

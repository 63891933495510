// React
import { useState, useEffect } from "react"

// Material UI
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { FormHelperText } from "@mui/material"

// Components
import AddressSelect from "./AddressSelect"

// Types
import { Address, Contact } from "xero-node"

// Contexts
import { useAuth } from "../context/AuthContext"

const API_KEY = process.env.REACT_APP_API_KEY

interface Props {
    defaultContactID: string | null
    // eslint-disable-next-line no-unused-vars
    onChange: (selectedContact: Contact | null) => void
    isRequired: boolean
}

const XeroContactSelect = ({ defaultContactID, onChange, isRequired }: Props) => {
    const auth = useAuth()
    const [loading, setLoading] = useState(true)
    const [contacts, setContacts] = useState<Contact[]>([])
    const [addresses, setAddresses] = useState<Address[] | null>(null)
    const [selectedContactData, setSelectedContactData] = useState<Contact | null>(null)

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            onChange(null)
            const resp = await fetch("/api/contacts", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Api-Key ${API_KEY}`,
                },
            })
            if (resp.status === 200) {
                const response = await resp.json()
                setContacts(response.contacts)
            } else {
                setContacts([])
            }
            setLoading(false)
        })()
    }, [auth.loading, auth.activeTenant]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleContactChange = (event: SelectChangeEvent) => {
        const contactData = contacts.find((c) => c.contactID === event.target.value) || null
        
        // Set addresses from contact addresses
        if (contactData?.addresses?.length) {
            setAddresses(contactData.addresses)
        }

        setSelectedContactData(contactData || null)
        onChange(contactData || null)
    }

    // Handle address select change
    const handleAddressChange = (selectedAddress: Address | null) => {
        if (selectedAddress) {
            const addresses = [selectedAddress]
            const selectedContactDataCopy = { ...selectedContactData }
            if (selectedContactDataCopy) {
                selectedContactDataCopy.addresses = addresses
            }
            onChange(selectedContactDataCopy)
        }
    }

    return (
        <Box sx={{ minWidth: 120, maxWidth: 500 }}>
            <FormControl fullWidth>
                <InputLabel id="contact-select-label" required={isRequired}>
                    Contact
                </InputLabel>
                <Select
                    labelId="contact-select-label"
                    id="contact-select"
                    label="Contact"
                    defaultValue={defaultContactID || ""}
                    onChange={handleContactChange}
                >
                    {loading ? (
                        <MenuItem value="0">Loading...</MenuItem>
                    ) : contacts.length > 0 ? (
                        contacts.map((i) => (
                            <MenuItem key={i.contactID} value={i.contactID}>
                                {i.name}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="0">
                            No Xero Contacts found. Try adding one in Xero.
                        </MenuItem>
                    )}
                </Select>
                <FormHelperText>Select a borrower from your Xero contacts.</FormHelperText>
            </FormControl>

            <Box sx={{ mt: 2 }}>
                <AddressSelect
                    addresses={addresses}
                    onChange={handleAddressChange}
                    isRequired={true}
                />
            </Box>
        </Box>
    )
}

export default XeroContactSelect

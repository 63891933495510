// React
import { BrowserRouter, Routes, Route } from "react-router-dom"

// Components
import Login from "./components/Login"
import CreateNew from "./components/CreateNew"
import MyDocuments from "./components/MyDocuments"
import ProtectedRoute from "./components/ProtectedRoute"
import LoginRoute from "./components/LoginRoute"

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <CreateNew />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/login"
                    element={
                        <LoginRoute>
                            <Login />
                        </LoginRoute>
                    }
                />

                <Route
                    path="/my-documents"
                    element={
                        <ProtectedRoute>
                            <MyDocuments />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}

export default App

// React
import React, { useState, useEffect, useContext, createContext } from "react"

// Types
import User from "../types/User"

const API_KEY = process.env.REACT_APP_API_KEY

interface AuthContextType {
    user: User | null
    activeTenant: any | null
    tenants: any[]
    loading: boolean
    signInWithXero: () => void
    changeTenant: (tenantId: string) => void
    signOut: () => void
}

const AuthContext: React.Context<AuthContextType> = createContext<AuthContextType>({
    user: null,
    activeTenant: null,
    tenants: [],
    loading: true,
    signInWithXero: () => {},
    changeTenant: (tenantId: string) => {},
    signOut: () => {},
})

export function AuthProvider({ children }: any) {
    const authProvider = useProvideAuth()
    return <AuthContext.Provider value={authProvider}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}

function useProvideAuth() {
    const [user, setUser] = useState<User | null>(null)
    const [activeTenant, setActiveTenant] = useState<any | null>(null)
    const [tenants, setTenants] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const handleUserData = async (rawUser?: any | null) => {
        if (rawUser) {
            const user: User = {
                id: rawUser.decodedIdToken.xero_userid,
                name: rawUser.decodedIdToken.name,
                email: rawUser.decodedIdToken.email,
            }
            setUser(user)
            setActiveTenant(rawUser.activeTenant)
            setTenants(rawUser.allTenants)
            setLoading(false)
        } else {
            setUser(null)
            setActiveTenant(null)
            setLoading(false)
        }
    }

    const signInWithXero = async () => {
        console.log("signInWithXero called", new Date())
        setLoading(true)

        const resp = await fetch("/api/connect", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Api-Key ${API_KEY}`,
            },
        })

        if (resp.status === 200) {
            const response = await resp.json()
            window.location.assign(response.consentUrl)
        } else {
            // TODO: Handle error
            handleUserData(null)
        }
    }

    const signOut = () => {
        fetch("/api/disconnect", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Api-Key ${API_KEY}`,
            },
        })
        handleUserData(null)
    }

    const changeTenant = async (tenantId: string) => {
        await fetch(`/api/active-tenant/${tenantId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Api-Key ${API_KEY}`,
            },
        }).then(async (resp) => {
            if (resp.status === 200) {
                const response = await resp.json()
                handleUserData(response.authData)
            } else {
                handleUserData(null)
            }
        })
    }

    useEffect(() => {
        fetch("/api/authData", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Api-Key ${API_KEY}`,
            },
        }).then(async (resp) => {
            if (resp.status === 200) {
                const response = await resp.json()
                handleUserData(response)
            } else {
                handleUserData(null)
            }
        })
    }, [])

    return {
        user,
        activeTenant,
        tenants,
        loading,
        signInWithXero,
        changeTenant,
        signOut,
    }
}

// React
import { useState, useEffect, Fragment } from "react"

// Material UI
import {
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    FormControl,
    Divider,
    TextField,
    Skeleton,
    Drawer,
    InputAdornment,
} from "@mui/material"
import { Box, Stack } from "@mui/system"
import { LoadingButton } from "@mui/lab"
import { Close } from "@mui/icons-material"

// Components
import OverdueInvoiceSelect from "./XeroOverdueInvoiceSelect"
import TemplateFAQs from "./TemplateFAQs"
import DeadlineInput from "./DeadlineInput"
import CourtRegistrySelect from "./CourtRegistrySelect"
import DocumentPreviewer from "./DocumentPreviewer"

// Data
import { templateFieldData } from "../data/TemplateFields"
import NSWCourtRegistries from "../data/NSWCourtRegistries"

// Types
import { Account, Contact, Invoice } from "xero-node"
import { Template } from "../types/Template"
import {
    XeroInvoiceMap,
    XeroContactMap,
    XeroOrganisationMap,
    XeroUserMap,
    XeroBankAccountMap,
    CourtRegistryMap,
} from "../data/TemplateVariableXeroMapping"

// Context
import { useAuth } from "../context/AuthContext"

// Utils
import { useSnackbar, OptionsObject } from "notistack"
import { TemplateVariableInputType, TemplateVariableSource } from "../types/TemplateVariableMeta"
import DocumentReferenceSelect from "./DocumentReferenceSelect"
import BankAccountSelect from "./BankAccountSelect"
import CourtRegistry from "../types/CourtRegistry"
import XeroContactSelect from "./XeroContactSelect"

const API_KEY = process.env.REACT_APP_API_KEY

const DEFAULT_COURT_REGISTRY: CourtRegistry =
    NSWCourtRegistries.find((courtRegistry) => courtRegistry.location === "Sydney Central") ??
    NSWCourtRegistries[0]

type DocumentGenerationRequestData = [string, string][]

interface DocumentGenerationRequestBody {
    id: string
    name: string
    recipientName: string
    templateId: string
    data: DocumentGenerationRequestData
}

const determineRecipientName = (contact: Contact | null, invoiceData: Invoice | null) => {
    if (contact) {
        return contact.name ?? "Unknown"
    } else if (invoiceData?.contact) {
        return invoiceData.contact.name ?? "Unknown"
    }
    return "Unknown"
}

interface CreateDocumentFormDialogProps {
    template: Template | null
    open: boolean
    onClose: () => void
}


const CreateDocumentFormDialog = ({ template, open, onClose }: CreateDocumentFormDialogProps) => {
    // Context variables
    const auth = useAuth()
    const { enqueueSnackbar } = useSnackbar()

    // State variables
    const [loadingTemplateData, setLoadingTemplateData] = useState(false)
    const [documentGenerationInProgress, setDocumentGenerationInProgress] = useState(false)
    const [inputControls, setInputControls] = useState<JSX.Element[]>([])
    const [variableStateStore, setVariableStateStore] = useState<Map<string, any>>(new Map())


    // Temporary state variables
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null)
    const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
    const [nswCourtRegistry, setNSWCourtRegistry] = useState<CourtRegistry | null>(null)
    const [selectedBankAccount, setSelectedBankAccount] = useState<Account | null>(null)

    // Function handlers
    const handleInvoiceChange = async (_selectedInvoice: Invoice | null) => {
        setSelectedInvoice(_selectedInvoice)
    }

    const handleContactChange = async (_selectedContact: Contact | null) => {
        setSelectedContact(_selectedContact)
    }

    const handleNSWCourtRegistryChange = (selectedRegistry: CourtRegistry | null) => {
        setNSWCourtRegistry(selectedRegistry || null)
    }

    const handleBankAccountChange = (_selectedBankAccount: Account | null) => {
        setSelectedBankAccount(_selectedBankAccount)
    }

    const addVariableState = (key: string, value: any) => {
        if (variableStateStore.has(key)) {
            setVariableStateStore((prev) => new Map(prev).set(key, value))
        } else {
            setVariableStateStore((prev) => new Map([...prev, [key, value]]))
        }
    }

    // Use effect hook
    useEffect(() => {
        !loadingTemplateData && setLoadingTemplateData(true)
        if (template) {
            // Reset some state before using
            setVariableStateStore(new Map())
            setInputControls([])

            // TODO: Determine required fields from template.
            const templateVariables = templateFieldData[template.id].variables
            const variablesMetaMap = templateFieldData[template.id].variableMetaMap

            const controls: JSX.Element[] = []

            if (
                Object.values(variablesMetaMap).find(
                    (meta) => meta.source === TemplateVariableSource.XeroInvoice,
                )
            ) {
                controls.push(
                    <OverdueInvoiceSelect
                        key="invoice-select"
                        defaultInvoiceID={null}
                        onChange={handleInvoiceChange}
                        isRequired={true}
                    />,
                )
            }

            if (
                Object.values(variablesMetaMap).find(
                    (meta) => meta.source === TemplateVariableSource.XeroContact,
                )
            ) {
                controls.push(
                    <XeroContactSelect
                        key="contact-select"
                        defaultContactID={null}
                        onChange={handleContactChange}
                        isRequired={true}
                    />,
                )
            }

            if (
                Object.values(variablesMetaMap).find(
                    (meta) => meta.source === TemplateVariableSource.XeroBankAccount,
                )
            ) {
                controls.push(
                    <BankAccountSelect
                        key="bank-account-select"
                        defaultAccountID={null}
                        onSelect={handleBankAccountChange}
                        isRequired={true}
                    />,
                )
            }

            if (
                Object.values(variablesMetaMap).find(
                    (meta) => meta.source === TemplateVariableSource.NSWCourtRegistry,
                )
            ) {
                controls.push(
                    <CourtRegistrySelect
                        key="court-registry-select"
                        registries={NSWCourtRegistries}
                        defaultRegistry={"Sydney Central"}
                        onChange={handleNSWCourtRegistryChange}
                        isRequired={true}
                    />,
                )
            }

            templateVariables.forEach((variable) => {
                try {
                    const variableMeta = variablesMetaMap[variable]
                    if (variableMeta.source === TemplateVariableSource.StandaloneInput) {
                        switch (variableMeta.inputType) {
                            case TemplateVariableInputType.Deadline: {
                                return controls.push(
                                    <DeadlineInput
                                        key={variable}
                                        label={variableMeta.inputLabel ?? "Deadline"}
                                        defaultDeadline={14}
                                        onChange={(selectedDeadline: string) => {
                                            addVariableState(variable, selectedDeadline)
                                        }}
                                        isRequired={variableMeta.isRequired}
                                    />,
                                )
                            }
                            case TemplateVariableInputType.PlainText: {
                                return controls.push(
                                    <TextField
                                        key={variable}
                                        fullWidth
                                        required={variableMeta.isRequired}
                                        label={variableMeta.inputLabel ?? "Enter text"}
                                        helperText={variableMeta.helperText ?? null}
                                        variant="outlined"
                                        margin="normal"
                                        onChange={(event) => {
                                            addVariableState(variable, event.target.value)
                                        }}
                                    />,
                                )
                            }
                            case TemplateVariableInputType.Email: {
                                addVariableState(variable, auth.user?.email ?? "")
                                return controls.push(
                                    <TextField
                                        key={variable}
                                        fullWidth
                                        required={variableMeta.isRequired}
                                        label={variableMeta.inputLabel ?? "Enter email"}
                                        helperText={variableMeta.helperText ?? null}
                                        variant="outlined"
                                        margin="normal"
                                        defaultValue={auth.user?.email ?? ""}
                                        onChange={(event) => {
                                            addVariableState(variable, event.target.value)
                                        }}
                                    />,
                                )
                            }
                            case TemplateVariableInputType.Amount: {
                                return controls.push(
                                    <TextField
                                        key={variable}
                                        fullWidth
                                        required={variableMeta.isRequired}
                                        label={variableMeta.inputLabel ?? "Enter amount"}
                                        helperText={variableMeta.helperText ?? null}
                                        variant="outlined"
                                        margin="normal"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">$</InputAdornment>
                                            ),
                                        }}
                                        onChange={(event) => {
                                            addVariableState(variable, event.target.value)
                                        }}
                                    />,
                                )
                            }
                            case TemplateVariableInputType.Percentage: {
                                return controls.push(
                                    <TextField
                                        key={variable}
                                        fullWidth
                                        required={variableMeta.isRequired}
                                        label={variableMeta.inputLabel ?? "Enter percentage"}
                                        helperText={variableMeta.helperText ?? null}
                                        variant="outlined"
                                        margin="normal"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                        }}
                                        onChange={(event) => {
                                            addVariableState(variable, event.target.value)
                                        }}
                                    />,
                                )
                            }
                            case TemplateVariableInputType.DocumentReference: {
                                return controls.push(
                                    <DocumentReferenceSelect
                                        key={variable}
                                        label={variableMeta.inputLabel}
                                        documentType={variableMeta.documentType ?? null}
                                        defaultDocumentID={null}
                                        onChange={(selectedDocumentID: string) => {
                                            addVariableState(variable, selectedDocumentID)
                                        }}
                                        isRequired={variableMeta.isRequired}
                                    />,
                                )
                            }
                        }
                    }
                } catch (error) {
                    console.log(`${variable} is not a valid template variable.`)
                    console.error(error)
                }
            })
            setInputControls(controls)

            loadingTemplateData && setLoadingTemplateData(false)
        } else {
            // TODO: Error handling.
        }
    }, [template, open]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async () => {
        if (process.env.NODE_ENV === "development") {
            console.log("Template ID: ", template?.templateId)
            console.log("Invoice ID:", selectedInvoice?.invoiceID)
        }

        if (template === null) {
            // Protection against an unlikely submit event when the template is null.
            return
        }

        if (selectedInvoice === null && selectedContact === null) {
            // If the invoice && contact is null we can fail early and should NOT submit the form.
            return
        }

        // TODO: Validate all required fields are filled out.

        


        setDocumentGenerationInProgress(true)

        const invoiceData = selectedInvoice as Invoice
        const contactData = selectedContact as Contact

        const templateVariables = templateFieldData[template.id].variables
        const variablesMetaMap = templateFieldData[template.id].variableMetaMap

        const documentGenerationRequestData: DocumentGenerationRequestData = []

        const recipientName = determineRecipientName(contactData, invoiceData)

        const findRequiredFields = (obj: any) => {
            const arr = Object.entries(obj)
            const requiredFields = arr.filter(([key,value]: any) => value.isRequired === true && value.source === 7)
            return requiredFields
        }

        const requiredFields = findRequiredFields(templateFieldData[template.id].variableMetaMap)
        
        if (process.env.NODE_ENV === "development"){
        console.log(requiredFields)            
        console.log(variableStateStore)
    }
        let requiredFieldsComplete: boolean = true
        requiredFields.forEach((field: any) => {
                if(!variableStateStore.has(field[0])) {
                    if (process.env.NODE_ENV === "development"){
                        console.log(`Variable state store does not have ${field}`)
                    }
                    requiredFieldsComplete = false
                    return
                }
        })

        if (!requiredFieldsComplete) {
            setDocumentGenerationInProgress(false)
            enqueueSnackbar("Required fields are incomplete.", {
                variant: "warning",
                autoHideDuration: 10000,
            })
            return
        }

        templateVariables.forEach((variable) => {
            try {
                const variableMeta = variablesMetaMap[variable]
                switch (variableMeta.source) {
                    case TemplateVariableSource.XeroInvoiceAndOrgData: {
                        const customEvaluation = variableMeta.evaluation?.(
                            invoiceData,
                            auth?.activeTenant?.orgData ?? null,
                        )
                        documentGenerationRequestData.push([variable, customEvaluation ?? ""])
                        break
                    }
                    case TemplateVariableSource.XeroInvoice: {
                        const invoiceVariableMapValue = XeroInvoiceMap[variable]
                        const invoiceVariableValue = invoiceVariableMapValue(invoiceData)
                        documentGenerationRequestData.push([variable, invoiceVariableValue ?? ""])
                        break
                    }
                    case TemplateVariableSource.XeroContact: {
                        const contactVariableMapValue = XeroContactMap[variable]
                        const contactVariableValue = contactVariableMapValue(contactData)
                        documentGenerationRequestData.push([variable, contactVariableValue ?? ""])
                        break
                    }
                    case TemplateVariableSource.XeroOrgData: {
                        const organisationVariableMapValue = XeroOrganisationMap[variable]
                        const organisationVariableValue = organisationVariableMapValue(
                            auth.activeTenant?.orgData,
                        )
                        documentGenerationRequestData.push([
                            variable,
                            organisationVariableValue ?? "",
                        ])
                        break
                    }
                    case TemplateVariableSource.XeroUser: {
                        const userVariableMapValue = XeroUserMap[variable]
                        const userVariableValue = userVariableMapValue(auth.user)
                        documentGenerationRequestData.push([variable, userVariableValue ?? ""])
                        break
                    }
                    case TemplateVariableSource.XeroBankAccount: {
                        if (selectedBankAccount === null) {
                            // If the bank account is null we can fail early and should NOT submit the form.
                            return
                        }
                        const bankAccountVariableMapValue = XeroBankAccountMap[variable]
                        const bankAccountVariableValue =
                            bankAccountVariableMapValue(selectedBankAccount)
                        documentGenerationRequestData.push([
                            variable,
                            bankAccountVariableValue ?? "",
                        ])
                        break
                    }
                    case TemplateVariableSource.NSWCourtRegistry: {
                        const courtRegistryVariableMapValue = CourtRegistryMap[variable]
                        const courtRegistryVariableValue = courtRegistryVariableMapValue(
                            nswCourtRegistry || DEFAULT_COURT_REGISTRY,
                        )
                        documentGenerationRequestData.push([
                            variable,
                            courtRegistryVariableValue ?? "",
                        ])
                        break
                    }
                    case TemplateVariableSource.Static: {
                        documentGenerationRequestData.push([
                            variable,
                            variableMeta.defaultValue ?? "",
                        ])
                        break
                    }
                    case TemplateVariableSource.DateNow: {
                        const dateNow = new Date()
                        const dateNowFormatted = `${dateNow.getDate()}/${
                            dateNow.getMonth() + 1
                        }/${dateNow.getFullYear()}`
                        documentGenerationRequestData.push([variable, dateNowFormatted])
                        break
                    }
                    case TemplateVariableSource.StandaloneInput: {
                        const value = variableStateStore.get(variable) ?? ""
                        if (variableMeta.evaluation) {
                            const customEvaluation = variableMeta.evaluation(value)
                            documentGenerationRequestData.push([variable, customEvaluation ?? ""])
                        } else {
                            documentGenerationRequestData.push([variable, value])
                        }
                        break
                    }
                    default: {
                        break
                    }
                }
            } catch (error) {
                console.log(`${variable} is not a valid template variable.`)
                console.error(error)
            }
        })

        const body: DocumentGenerationRequestBody = {
            id: template.id,
            name: template.name,
            recipientName: recipientName,
            templateId: template.templateId,
            data: documentGenerationRequestData,
        }

        const resp = await fetch("/api/documents/generate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Api-Key ${API_KEY}`,
            },
            body: JSON.stringify(body),
        })

        const response = await resp.json()

        // Document generation request completed.
        setDocumentGenerationInProgress(false)

        if (resp.status === 200) {
            enqueueSnackbar("Your document is ready to download", {
                variant: "documentComplete",
                documentId: response.documentId,
            } as OptionsObject<"documentComplete">)
        } else {
            enqueueSnackbar("There was an error generating your document", {
                variant: "warning",
                autoHideDuration: 10000,
            })
        }

        // Close the modal regardless of success or failure.
        onClose()
    }

    return (
        <Fragment>
            <Drawer anchor="right" open={open} onClose={onClose}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography sx={{ flexGrow: 1 }} variant="h6" color="inherit">
                            New {template?.name}
                        </Typography>
                        <IconButton onClick={onClose} color="inherit">
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box p={4}>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        justifyContent="space-evenly"
                        spacing={4}
                    >
                        <Stack spacing={4}>
                            <DocumentPreviewer url={template?.pdfPreviewUrl ?? ""} width={300} />
                            <TemplateFAQs faqs={template?.faqs || []} />
                        </Stack>
                        <Stack sx={{ minWidth: 300 }} spacing={4}>
                            {loadingTemplateData ? (
                                <Skeleton variant="rectangular" height={200} />
                            ) : (
                                inputControls.map((control) => control)
                            )}

                            <FormControl>
                                <LoadingButton
                                    loading={documentGenerationInProgress}
                                    disabled={loadingTemplateData || documentGenerationInProgress}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Create document
                                </LoadingButton>
                            </FormControl>
                        </Stack>
                    </Stack>
                </Box>
            </Drawer>
        </Fragment>
    )
}

export default CreateDocumentFormDialog

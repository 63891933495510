// Money formatter
export const moneyFormatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: 2,
})

export const formatMoneyString = (moneyString: string) => {
    var number = Number(moneyString.replace(/[^0-9\.]+/g, ""))
    return moneyFormatter.format(number)
}

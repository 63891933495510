// React
import { useState } from "react"

// Material UI
import { TableRow, TableCell, Stack } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import DownloadIcon from "@mui/icons-material/Download"
import SendIcon from "@mui/icons-material/Send"

// Types
import { Document } from "../types/Document"

// Utils
import { formatDate } from "../lib/DateUtils"

const API_KEY = process.env.REACT_APP_API_KEY

interface Props {
    index: number
    document: Document
    // eslint-disable-next-line no-unused-vars
    onDocumentDelivery: (document: Document) => void
}

const DocumentRow = ({ index, document, onDocumentDelivery }: Props) => {
    const [downloading, setDownloading] = useState(false)

    const handleDocumentDownload = async (documentId: string) => {
        setDownloading(true)
        const resp = await fetch(`/api/documents/${documentId}/export`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Api-Key ${API_KEY}`,
            },
        })

        if (resp.status === 200) {
            const response = await resp.json()
            console.log(response.signedUrl)
            window.open(response.signedUrl, "_blank")
        }

        setDownloading(false)
    }

    return (
        <TableRow key={document.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="left">{formatDate(document.createdAt)}</TableCell>
            <TableCell>{document.name}</TableCell>
            <TableCell>{document.recipient}</TableCell>
            <TableCell align="right">{document.status}</TableCell>
            <TableCell align="right">
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <LoadingButton
                        variant="outlined"
                        onClick={() => handleDocumentDownload(document.id)}
                        loading={downloading}
                        startIcon={<DownloadIcon />}
                    >
                        Download
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        onClick={() => onDocumentDelivery(document)}
                        endIcon={<SendIcon />}
                    >
                        Deliver
                    </LoadingButton>
                </Stack>
            </TableCell>
        </TableRow>
    )
}

export default DocumentRow

import { Navigate } from "react-router-dom"
import { useAuth } from "../context/AuthContext"

import FullPageLoader from "./FullPageLoader"

function ProtectedRoute({ children }: any) {
    const auth = useAuth()
    return auth.loading ? <FullPageLoader /> : auth.user ? children : <Navigate to="/login" />
}

export default ProtectedRoute

// Types
import { Invoice, Account, Contact, Address } from "xero-node"
import CourtRegistry from "../types/CourtRegistry"
import { TemplateVariableSource } from "../types/TemplateVariableMeta"
import fees from "./Fees"

// Utils
import { daysBetween, formatDateString } from "../lib/DateUtils"
import { moneyFormatter } from "../lib/MoneyUtils"
import { getFormattedStringFromPhone } from "../lib/PhoneUtils"

function bestAddress(addresses?: Address[]): Address | null {
    if (addresses?.length ?? 0 > 1) {
        // Loop through items and return the first one that contains the most properties
        let bestAddress: Address | null = null
        let bestAddressScore = 0
        addresses?.forEach((address) => {
            let score = 0
            if (address.addressLine1) {
                score += 1
            }
            if (address.addressLine2) {
                score += 1
            }
            if (address.city) {
                score += 1
            }
            if (address.region) {
                score += 1
            }
            if (address.postalCode) {
                score += 1
            }
            if (address.country) {
                score += 1
            }
            if (score > bestAddressScore) {
                bestAddress = address
                bestAddressScore = score
            }
        })
        return bestAddress
    }
    return addresses?.[0] || null
}

export const CourtRegistryMap: { [key: string]: (r: CourtRegistry) => string } = {
    "{{RegistryName}}": (r: CourtRegistry) => r.location,
    "{{RegistryStreetAddress}}": (r: CourtRegistry) => r.streetAddress,
    "{{RegistryPostalAddress}}": (r: CourtRegistry) => r.postalAddress,
    "{{RegistryPhone}}": (r: CourtRegistry) => r.phone,
}

export const XeroUserMap: { [key: string]: (u: any) => string | null } = {
    "{{CreditorUserName}}": (u: any) => u.name || null,
    "{{CreditorUserEmail}}": (u: any) => u.email || null,
}

export const XeroBankAccountMap: { [key: string]: (a: Account) => string | null } = {
    "{{CreditorAccName}}": (a: Account) => a.name || null,
    "{{CreditorBSB}}": (a: Account) => {
        return a.bankAccountNumber?.substring(0, 6) || null
    },
    "{{CreditorAccNo}}": (a: Account) => {
        return a.bankAccountNumber?.substring(6) || null
    },
    "{{CreditorAccNumber}}": (a: Account) => {
        return a.bankAccountNumber?.substring(6) || null
    },
}

// Address in array should be set above the organisation level

export const XeroOrganisationMap: { [key: string]: (o: any) => string | null } = {
    "{{CreditorName}}": (o: any) => o.legalName || null,
    "{{CreditorACN}}": (o: any) => {
        const registrationNumber = o.registrationNumber?.replace(/\s/g, "")
        if (registrationNumber?.length === 9) {
            const formattedACN = registrationNumber?.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3")
            return "ACN " + formattedACN
        } else if (registrationNumber?.length === 11) {
            const formattedABN = registrationNumber?.replace(
                /(\d{2})(\d{3})(\d{3})(\d{3})/,
                "$1 $2 $3 $4",
            )
            return "ABN " + formattedABN
        }
        return null
    },
    "{{CreditorAddLine1}}": (o: any) => bestAddress(o.addresses)?.addressLine1 || null,
    "{{CreditorAddLine2}}": (o: any) => bestAddress(o.addresses)?.addressLine2 || null,
    "{{CreditorAddSuburb}}": (o: any) => bestAddress(o.addresses)?.city || null,
    "{{CreditorAddState}}": (o: any) => bestAddress(o.addresses)?.region || null,
    "{{CreditorAddPostcode}}": (o: any) => bestAddress(o.addresses)?.postalCode || null,
    "{{CreditorPostcode}}": (o: any) => bestAddress(o.addresses)?.postalCode || null,
    "{{CreditorUserTelephone}}": (o: any) => {
        const phone = o.phones?.[0]
        return getFormattedStringFromPhone(phone) || null
    },
}

// Where some values, like ACN, are null we should check the variable rules and if `shouldUserBeAbleToEdit` is true and required is true, we should require the user to provide the value and validate the form field as required.

export const XeroInvoiceMap: { [key: string]: (i: Invoice) => string | null } = {
    "{{DebtorName}}": (i: Invoice) => i.contact?.name || null,
    "{{DebtorACN}}": (i: Invoice) => {
        const companyNumber = i.contact?.companyNumber?.replace(/\s/g, "")
        if (companyNumber?.length === 9) {
            const formattedACN = companyNumber?.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3")
            return "ACN " + formattedACN
        } else if (companyNumber?.length === 11) {
            const formattedABN = companyNumber?.replace(
                /(\d{2})(\d{3})(\d{3})(\d{3})/,
                "$1 $2 $3 $4",
            )
            return "ABN " + formattedABN
        }
        return null
    },
    "{{DebtorAddLine1}}": (i: Invoice) => bestAddress(i.contact?.addresses)?.addressLine1 || null,
    "{{DebtorAddLine2}}": (i: Invoice) => bestAddress(i.contact?.addresses)?.addressLine2 || null,
    "{{DebtorAddSuburb}}": (i: Invoice) => bestAddress(i.contact?.addresses)?.city || null,
    "{{DebtorAddState}}": (i: Invoice) => bestAddress(i.contact?.addresses)?.region || null,
    "{{DebtorAddPostcode}}": (i: Invoice) => bestAddress(i.contact?.addresses)?.postalCode || null,
    "{{DebtorPostcode}}": (i: Invoice) => bestAddress(i.contact?.addresses)?.postalCode || null,
    "{{DocNo}}": (i: Invoice) => i.invoiceNumber || null,
    "{{DocDate}}": (i: Invoice) => formatDateString(i.date),
    "{{InvoiceDate}}": (i: Invoice) => formatDateString(i.date),
    "{{DebtAmount}}": (i: Invoice) => (i.amountDue ? moneyFormatter.format(i.amountDue) : null),
    "{{AmountOwing}}": (i: Invoice) => (i.amountDue ? moneyFormatter.format(i.amountDue) : null),
    "{{DebtDate}}": (i: Invoice) => formatDateString(i.dueDate),
    "{{NumberOfDaysOverdue}}": (i: Invoice) => {
        if (!i.dueDate) {
            return null
        }
        const dueDate = new Date(i.dueDate)
        const now = new Date()
        return daysBetween(now, dueDate).toString()
    },
    "{General/Small Claims Division}": (i: Invoice) => {
        const amountDue = i.amountDue ? i.amountDue : 0
        if (amountDue > fees.smallClaimsThreshold) {
            return "General Division"
        } else {
            return "Small Claims Division"
        }
    },
    "{{InvoiceDeadline}}": (i: Invoice) => {
        if (!i.dueDate || !i.date) {
            return null
        }
        const issueDate = new Date(i.date)
        const dueDate = new Date(i.dueDate)
        return daysBetween(dueDate, issueDate).toString()
    },
    "{{DebtorUserName}}": (i: Invoice) => i.contact?.firstName + " " + i.contact?.lastName || null,
    "{{DebtorUserEmail}}": (i: Invoice) => i.contact?.emailAddress || null,
}

export const XeroContactMap: { [key: string]: (c: Contact) => string | null } = {
    "{{DebtorName}}": (c: Contact) => c.name || null,
    "{{DebtorACN}}": (c: Contact) => {
        const companyNumber = c.companyNumber?.replace(/\s/g, "")
        if (c.companyNumber?.length === 9) {
            const formattedACN = companyNumber?.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3")
            return "ACN " + formattedACN
        } else if (companyNumber?.length === 11) {
            const formattedABN = companyNumber?.replace(
                /(\d{2})(\d{3})(\d{3})(\d{3})/,
                "$1 $2 $3 $4",
            )
            return "ABN " + formattedABN
        }
        return null
    },
    "{{DebtorAddLine1}}": (c: Contact) => bestAddress(c.addresses)?.addressLine1 || null,
    "{{DebtorAddLine2}}": (c: Contact) => bestAddress(c.addresses)?.addressLine2 || null,
    "{{DebtorAddSuburb}}": (c: Contact) => bestAddress(c.addresses)?.city || null,
    "{{DebtorAddState}}": (c: Contact) => bestAddress(c.addresses)?.region || null,
    "{{DebtorAddPostcode}}": (c: Contact) => bestAddress(c.addresses)?.postalCode || null,
    "{{DebtorPostcode}}": (c: Contact) => bestAddress(c.addresses)?.postalCode || null,
    "{{DebtorUserEmail}}": (c: Contact) => c?.emailAddress || null,
    "{{BorrowerEmail}}": (c: Contact) => c?.emailAddress || null,
}

export const SourceMap: { [key: number]: any } = {
    [TemplateVariableSource.XeroBankAccount.valueOf()]: XeroBankAccountMap,
    [TemplateVariableSource.XeroOrgData.valueOf()]: XeroOrganisationMap,
    [TemplateVariableSource.XeroInvoice.valueOf()]: XeroInvoiceMap,
    [TemplateVariableSource.NSWCourtRegistry.valueOf()]: CourtRegistryMap,
}

import CourtRegistry from "../types/CourtRegistry"

export const NSWCourtRegistries: CourtRegistry[] = [
    {
        location: "Albion Park",
        streetAddress: "96 Tongarra Rd, Albion Park NSW 2527",
        postalAddress: "PO Box 30 Albion Park NSW 2527",
        phone: "1300 679 272",
    },
    {
        location: "Albury",
        streetAddress: "515 Olive St, Albury NSW 2640",
        postalAddress: "PO Box 64 Albury NSW 2640",
        phone: "1300 679 272",
    },
    {
        location: "Armidale",
        streetAddress: "5 Moore St, Armidale NSW 2350",
        postalAddress: "PO Box 91 Armidale NSW 2350",
        phone: "1300 679 272",
    },
    {
        location: "Ballina",
        streetAddress: "Cnr River & Martin Streets, Ballina NSW 2478",
        postalAddress: "PO Box 8 Ballina NSW 2478",
        phone: "6681 2200",
    },
    {
        location: "Balmain",
        streetAddress: "368 Darling St, Balmain NSW 2041",
        postalAddress: "PO Box 40 Balmain NSW 2041",
        phone: "1300 679 272",
    },
    {
        location: "Balranald",
        streetAddress: "Cnr Moppett and Pine Street Hay NSW 2711",
        postalAddress: "PO Box 32 Hay NSW 2711",
        phone: "1300 679 272",
    },
    {
        location: "Bankstown",
        streetAddress: "Cnr Chapel Road & The Mall, Bankstown NSW 2200",
        postalAddress: "PO Box 71 Bankstown NSW 2200",
        phone: "1300 679 272",
    },
    {
        location: "Batemans Bay",
        streetAddress: "24 Orient St, Batemans Bay NSW 2536",
        postalAddress: "PO Box 203 Batemans Bay NSW 2536",
        phone: "02 4478 2400",
    },
    {
        location: "Bathurst",
        streetAddress: "Russell Street, Bathurst NSW 2795",
        postalAddress: "PO Box 32 Bathurst NSW 2795",
        phone: "1300 679 272",
    },
    {
        location: "Bega",
        streetAddress: "Cnr Gipps & Carp St, Bega NSW 2550",
        postalAddress: "PO Box 31 Bega NSW 2550",
        phone: "1300 679 272",
    },
    {
        location: "Bellingen",
        streetAddress: "48 Hyde St, Bellingen NSW 2454",
        postalAddress: "PO Box 18 Coffs Harbour NSW 2450",
        phone: "1300 679 272",
    },
    {
        location: "Belmont",
        streetAddress: "530 Pacific Highway, Belmont NSW 2280",
        postalAddress: "PO Box 42 GPO Belmont NSW 2280",
        phone: "1300 679 272",
    },
    {
        location: "Blacktown",
        streetAddress: "1 Kildare Rd, Blacktown 2148",
        postalAddress: "PO Box 217 Blacktown NSW 2148",
        phone: "1300 679 272",
    },
    {
        location: "Blayney",
        streetAddress: "Adelaide St, Blayney NSW 2799",
        postalAddress: "PO Box 26 Blayney NSW 2799",
        phone: "6336 7650",
    },
    {
        location: "Boggabilla",
        streetAddress: "Merriwa St, Boggabilla NSW 2409",
        postalAddress: "PO Box 14 Moree NSW 2400",
        phone: "1300 679 272",
    },
    {
        location: "Bombala",
        streetAddress: "High St, Bombala NSW 2632",
        postalAddress: "PO Box 53 Bombala NSW 2632",
        phone: "1300 679 272",
    },
    {
        location: "Bourke",
        streetAddress: "55-57 Oxley Street, Bourke NSW 2840",
        postalAddress: "PO Box 5 Bourke NSW 2840",
        phone: "1300 679 272",
    },
    {
        location: "Braidwood",
        streetAddress: "170 Wallace Street, Braidwood NSW 2622",
        postalAddress: "2 Farrer Place Queanbeyan NSW 2620",
        phone: "1300 679 272",
    },
    {
        location: "Brewarrina",
        streetAddress: "Bathurst St, Brewarrina NSW 2839",
        postalAddress: "PO Box 112 Brewarrina NSW 2839",
        phone: "6839 2224",
    },
    {
        location: "Broadmeadow",
        streetAddress: "19-23 Lambton Road, Broadmeadow NSW 2292",
        postalAddress: "PO Box 148 Broadmedow NSW 2292",
        phone: "4915 5200",
    },
    {
        location: "Broken Hill",
        streetAddress: "240 Argent St, Broken Hill 2880",
        postalAddress: "PO Box 260 Broken Hill NSW 2880",
        phone: "1300 679 272",
    },
    {
        location: "Burwood",
        streetAddress: "7-9 Belmore St, Burwood NSW 2134",
        postalAddress: "PO Box 235 Burwood NSW 1805",
        phone: "1300 679 272",
    },
    {
        location: "Byron Bay",
        streetAddress: "7 Middleton St, Byron Bay NSW 2481",
        postalAddress: "PO Box 70 Byron Bay NSW 2481",
        phone: "6681 2250",
    },
    {
        location: "Camden",
        streetAddress: "John St, Camden NSW 2570",
        postalAddress: "PO Box 91 Campbelltown NSW 2560",
        phone: "1300 679 272",
    },
    {
        location: "Campbelltown",
        streetAddress: "Railway St,  Campbelltown NSW 2560",
        postalAddress: "PO Box 91 Campbelltown NSW 2560",
        phone: "1300 679 272",
    },
    {
        location: "Casino",
        streetAddress: "68 Walker Street, Casino NSW 2470",
        postalAddress: "PO Box 21 Casino NSW 2470",
        phone: "6671 5660",
    },
    {
        location: "Sydney Central",
        streetAddress: "98 Liverpool St, Sydney NSW 2000",
        postalAddress: "PO Box 20495 World Square NSW 2000",
        phone: "1300 679 272",
    },
    {
        location: "Cessnock",
        streetAddress: "223 Maitland Rd, Cessnock NSW 2325",
        postalAddress: "PO Box 24 Cessnock NSW 2325",
        phone: "1300 679 272",
    },
    {
        location: "Coffs Harbour",
        streetAddress: "2 Beryl St, Coffs Harbour NSW 2450",
        postalAddress: "PO Box 18 Coffs Harbour NSW 2450",
        phone: "1300 679 272",
    },
    {
        location: "Condobolin",
        streetAddress: "Bathurst Street, Condobolin NSW 2877",
        postalAddress: "PO Box Condobolin NSW 2877",
        phone: "1300 679 272",
    },
    {
        location: "Cooma",
        streetAddress: "Vale St, Cooma NSW 2630",
        postalAddress: "PO Box 203 Cooma NSW 2630",
        phone: "6453 4900",
    },
    {
        location: "Coonabarabran",
        streetAddress: "48 Delgarno St, Coonabarabran NSW 2357",
        postalAddress: "PO Box 37 Coonabarabran NSW 2357",
        phone: "1300 679 272",
    },
    {
        location: "Coonamble",
        streetAddress: "27 Aberford St, Coonamble NSW 2829",
        postalAddress: "PO Box 37 Coonamble NSW 2829",
        phone: "1300 679 272",
    },
    {
        location: "Cootamundra",
        streetAddress: "298 Parker St, Cootamundra NSW 2590",
        postalAddress: "PO Box 148 Cootamundra NSW 2590",
        phone: "6942 8600",
    },
    {
        location: "Corowa",
        streetAddress: "Cnr Church & Queen Sts, Corowa NSW 2646",
        postalAddress: "PO Box 10 Corowa NSW 2646",
        phone: "1300 679 272",
    },
    {
        location: "Cowra",
        streetAddress: "Cnr Kendal & Brisbane Sts, Cowra NSW 2794",
        postalAddress: "PO Box 18 Cowra NSW 2794",
        phone: "6342 7000",
    },
    {
        location: "Crookwell",
        streetAddress: "Goulburn St, Crookwell NSW 2583",
        postalAddress: "PO Box 188 Crookwell NSW 2583",
        phone: "1300 679 272",
    },
    {
        location: "Deniliquin",
        streetAddress: "Cnr Poictier & Wellington Sts, Deniliquin NSW 2710",
        postalAddress: "PO Box 42 Deniliquin NSW 2710",
        phone: "1300 679 272",
    },
    {
        location: "Downing Centre Local",
        streetAddress: "Level 4 Downing Centre 143-147 Liverpool Street, Sydney NSW 2000",
        postalAddress: "PO Box A4 Sydney South 1235",
        phone: "1300 679 272",
    },
    {
        location: "Drug Court",
        streetAddress: "Parramatta Court House 12 George Street Parramatta",
        postalAddress: "PO Box 92 Parramatta 2124",
        phone: "8688 4525",
    },
    {
        location: "Dubbo",
        streetAddress: "Brisbane St, Dubbo NSW 2830",
        postalAddress: "PO Box 50 Dubbo NSW 2830",
        phone: "1300 679 272",
    },
    {
        location: "Dunedoo",
        streetAddress: "24 Digilah St, Dubbo NSW 2830",
        postalAddress: "C/O Dubbo Court PO Box 50 Dubbo NSW 2830",
        phone: "1300 679 272",
    },
    {
        location: "Dungog",
        streetAddress: "Lord St, Dungog NSW 2420",
        postalAddress: "PO Box 596 Raymond Terrace NSW 2324",
        phone: "1300 679 272",
    },
    {
        location: "East Maitland",
        streetAddress: "John St, East Maitland NSW 2323",
        postalAddress: "PO Box 2, East Maitland NSW 2323",
        phone: "4931 6333",
    },
    {
        location: "Eden",
        streetAddress: "Imlay St, EDEN NSW 2551",
        postalAddress: "C/- PO Box 31 Bega NSW 2550",
        phone: "6497 2800",
    },
    {
        location: "Fairfield",
        streetAddress: "Cnr Spencer St & Court Rd, Fairfield NSW 2165",
        postalAddress: "PO Box 201 Fairfield NSW 2165",
        phone: "1300 679 272",
    },
    {
        location: "Finley",
        streetAddress: "Cnr Denison & Ulupna Sts, Finley NSW 2713",
        postalAddress: "PO Box 123 Finley NSW 2713",
        phone: "1300 679 272",
    },
    {
        location: "Forbes",
        streetAddress: "Victoria Lane, Forbes NSW 2871",
        postalAddress: "PO Box 130 Forbes NSW 2871",
        phone: "6852 5100",
    },
    {
        location: "Forensic Medicine and Coroners Court",
        streetAddress: "1A Main Ave Lidcombe NSW 2141",
        postalAddress: "1A Main Ave Lidcombe NSW 2141",
        phone: "8584 7777",
    },
    {
        location: "Forster",
        streetAddress: "Lake St, Forster NSW 2428",
        postalAddress: "PO Box 114 Taree NSW 2430",
        phone: "1300 679 272",
    },
    {
        location: "Gilgandra",
        streetAddress: "10 Myrtle St, Gilgandra NSW 2827",
        postalAddress: "C/O Dubbo Court PO Box 50 Dubbo NSW 2830",
        phone: "1300 679 272",
    },
    {
        location: "Glen Innes",
        streetAddress: "Grey St, Glen Innes NSW 2370",
        postalAddress: "PO Box 91 Armidale NSW 2350",
        phone: "6759 2351",
    },
    {
        location: "Gloucester",
        streetAddress: "Church St, Gloucester NSW 2422",
        postalAddress: "PO Box 596 Raymond Terrace  NSW 2324",
        phone: "1300 679 272",
    },
    {
        location: "Gosford",
        streetAddress: "Donnison St & Henry Parry Drive, Gosford NSW 2250",
        postalAddress: "135 Donnison St Gosford NSW 2250",
        phone: "1300 679 272",
    },
    {
        location: "Goulburn",
        streetAddress: "4 Montague St, Goulburn NSW 2580",
        postalAddress: "PO Box 474 Goulburn NSW 2580",
        phone: "1300 679 272",
    },
    {
        location: "Grafton",
        streetAddress: "47 Victoria St, Grafton NSW 2460",
        postalAddress: "PO Box 16 Grafton NSW 2460",
        phone: "1300 679 272",
    },
    {
        location: "Grenfell",
        streetAddress: "Camp St, Grenfell NSW 2810",
        postalAddress: "PO Box 18 Cowra NSW 279",
        phone: "6342 7100",
    },
    {
        location: "Griffith",
        streetAddress: "363 Banna Avenue, Griffith NSW 2680",
        postalAddress: "PO Box 379 Griffith NSW 2680",
        phone: "1300 679 272",
    },
    {
        location: "Gulgong",
        streetAddress: "101 Herbert St, Gulgong NSW 2852",
        postalAddress: "PO Box 43, Gulgong NSW 2852",
        phone: "6371 5750",
    },
    {
        location: "Gundagai",
        streetAddress: "161-169 Sheridan St, Gundagai NSW 2722",
        postalAddress: "PO Box 84 Gundagai NSW 2722",
        phone: "6981 5203",
    },
    {
        location: "Gunnedah",
        streetAddress: "287 Conadilly St, Gunnedah NSW 2380",
        postalAddress: "PO Box 241 Gunnedah NSW 2380",
        phone: "6748 4814",
    },
    {
        location: "Hay",
        streetAddress: "Cnr Moppett & Pine Streets, Hay NSW 2711",
        postalAddress: "PO Box 32 Hay NSW 2711",
        phone: "1300 679 272",
    },
    {
        location: "Hillston",
        streetAddress: "Cnr Molesworth & McGee Streets, Hillston NSW 2675",
        postalAddress: "PO Box 379 Griffith NSW 2680",
        phone: "1300 679 272",
    },
    {
        location: "Holbrook",
        streetAddress: "64 Albury St, Holbrook NSW 2644",
        postalAddress: "PO Box 17 Holbrook NSW 2644",
        phone: "1300 679 272",
    },
    {
        location: "Hornsby",
        streetAddress: "294 Peats Ferry Rd, Hornsby New 2077",
        postalAddress: "PO Box 96 Hornsby 1630",
        phone: "1300 679 272",
    },
    {
        location: "Inverell",
        streetAddress: "115-119 Otho Street, Inverell NSW 2360",
        postalAddress: "PO Box 23 Inverell NSW 2360",
        phone: "6721 7400",
    },
    {
        location: "Junee",
        streetAddress: "Belmore Street, JUNEE NSW 2663",
        postalAddress: "PO Box 14 Junee NSW 2663",
        phone: "6982 7840",
    },
    {
        location: "Katoomba",
        streetAddress: "5-11 Civic Place, Katoomba NSW 2780",
        postalAddress: "PO Box 209 Katoomba NSW 2780",
        phone: "1300 679 272",
    },
    {
        location: "Kempsey",
        streetAddress: "2 Sea Street, West Kempsey NSW 2440",
        postalAddress: "PO Box 3006 West Kempsey NSW 2440",
        phone: "1300 679 272",
    },
    {
        location: "Kiama",
        streetAddress: "34-36 Terralong Street, Kiama NSW 2533",
        postalAddress: "PO Box 27 Kiama NSW 2533",
        phone: "1300 679 272",
    },
    {
        location: "Kogarah",
        streetAddress: "17 Montgomery Street, Kogarah NSW 2217",
        postalAddress: "C/- Sutherland PO Box 37 Sutherland NSW 22132",
        phone: "1300 679 272",
    },
    {
        location: "Kurri Kurri",
        streetAddress: "Cnr Victoria and Lang Street, Kurri Kurri NSW 2327",
        postalAddress: "PO Box 8 Kurri Kurri NSW 2327",
        phone: "1300 679 272",
    },
    {
        location: "Kyogle",
        streetAddress: "Geneva Street, KYOGLE NSW 2474",
        postalAddress: "PO Box 18 Lismore NSW 2480",
        phone: "1300 679 272",
    },
    {
        location: "Lake Cargelligo",
        streetAddress: "51 Canada Street, Lake Cargelligo NSW 2671",
        postalAddress: "PO Box 79 Lake Cargelligo NSW 2671",
        phone: "6898 1005",
    },
    {
        location: "Leeton",
        streetAddress: "Church Street, Leeton NSW 2705",
        postalAddress: "PO Box 14 Leeton NSW 2705",
        phone: "1300 679 272",
    },
    {
        location: "Lightning Ridge",
        streetAddress: "Cnr Opal and Morilla Street, Lightning Ridge NSW 2834",
        postalAddress: "PO Box 504 Lightning Ridge NSW 2834",
        phone: "02 6820 5300",
    },
    {
        location: "Lismore",
        streetAddress: "9-11 Zadoc Street, Lismore NSW 2480",
        postalAddress: "PO Box 18 Lismore NSW 2480",
        phone: "1300 679 272",
    },
    {
        location: "Lithgow",
        streetAddress: "Cnr Bridge & Mort Streets, Lithgow NSW 2790",
        postalAddress: "PO Box 18 Lithgow NSW 2790",
        phone: "6352 7300",
    },
    {
        location: "Liverpool",
        streetAddress: "150 George St, Liverpool NSW 2170",
        postalAddress: "PO Box 3435 Liverpool Westfields NSW 2170",
        phone: "1300 679 272",
    },
    {
        location: "Lockhart",
        streetAddress: "Cnr Mathews & Hebden Streets, LOCKHART NSW 2656",
        postalAddress: "PO Box 5862, Wagga Wagga NSW 2650",
        phone: "1300 679 272",
    },
    {
        location: "Macksville",
        streetAddress: "River Street, Macksville NSW 2447",
        postalAddress: "PO Box 135 Macksville NSW 2447",
        phone: "6569 2802",
    },
    {
        location: "Maclean",
        streetAddress: "2 Macnaughton Place, Maclean NSW 2463",
        postalAddress: "PO Box 7 Maclean NSW 2463",
        phone: "6569 2850",
    },
    {
        location: "Maitland",
        streetAddress: "High Street, Maitland NSW 2320",
        postalAddress: "PO Box 14 Maitland NSW 2320",
        phone: "1300 679 272",
    },
    {
        location: "Manilla",
        streetAddress: "Cnr Court and Manilla Streets, Manilla NSW 2346",
        postalAddress: "PO Box 32 Manilla NSW 2346",
        phone: "1300 679 272",
    },
    {
        location: "Manly",
        streetAddress: "2 Belgrave Street, Manly NSW 2095",
        postalAddress: "PO Box 125 Manly NSW 1655",
        phone: "1300 679 272",
    },
    {
        location: "Milton",
        streetAddress: "64 Princes Hwy, Milton NSW 2538",
        postalAddress: "PO Box 94 Milton NSW 2538",
        phone: "4478 2450",
    },
    {
        location: "Moama",
        streetAddress: "Cnr Francis and Maiden Streets, Moama NSW 2731",
        postalAddress: "PO Box 42 Deniliquin NSW 2710",
        phone: "1300 679 272",
    },
    {
        location: "Moree",
        streetAddress: "Frome Street, Moree NSW 2400",
        postalAddress: "PO Box 14 Moree NSW 2400",
        phone: "1300 679 272",
    },
    {
        location: "Moruya",
        streetAddress: "66 Vulcan Street, Moruya NSW 2537",
        postalAddress: "PO Box 33 Moruya NSW 2537",
        phone: "1300 679 272",
    },
    {
        location: "Moss vale",
        streetAddress: "356 Argyle Street, Moss Vale NSW 2577",
        postalAddress: "PO Box 7 Moss Vale NSW 2577",
        phone: "1300 679 272",
    },
    {
        location: "Moulamein",
        streetAddress: "Tallow Street, MOULAMEIN NSW 2733",
        postalAddress: "PO Box 42 Deniliquin 2710",
        phone: "1300 679 272",
    },
    {
        location: "Mt Druitt",
        streetAddress: "59 North Parade, Mount Druitt NSW 2770",
        postalAddress: "PO Box 99 Mt Druitt NSW 2770",
        phone: "1300 679 272",
    },
    {
        location: "Mudgee",
        streetAddress: "96 Market Street, Mudgee NSW 2850",
        postalAddress: "PO Box 22 Mudgee NSW 2850",
        phone: "1300 679 272",
    },
    {
        location: "Mullumbimby",
        streetAddress: "59-61 Dalley Street, Mullumbimby NSW 2482",
        postalAddress: "PO Box 37 Mullumbimby NSW 2482",
        phone: "6681 2250",
    },
    {
        location: "Mungindi",
        streetAddress: "146 St George Street, Mungindi NSW 2406",
        postalAddress: "PO Box 14 Moree NSW 2400",
        phone: "1300 679 272",
    },
    {
        location: "Murrurundi",
        streetAddress: "Murulla Street, Murrurundi NSW 2338",
        postalAddress: "PO Box 3 Murrurundi NSW 2338",
        phone: "1300 679 272",
    },
    {
        location: "Murwillumbah",
        streetAddress: "61 Murwillumbah Street, Murwillumbah NSW 2484",
        postalAddress: "PO Box 22 Murwillumbah NSW 2484",
        phone: "6671 5600",
    },
    {
        location: "Muswellbrook",
        streetAddress: "9/15 Bridge Street, Muswellbrook NSW 2333",
        postalAddress: "PO Box 247 Muswellbrook NSW 2333",
        phone: "1300 679 272",
    },
    {
        location: "Narooma",
        streetAddress: "Princes Highway, Narooma NSW 2546",
        postalAddress: "PO Box 548 Narooma NSW 2546",
        phone: "6497 2820",
    },
    {
        location: "Narrabri",
        streetAddress: "56 Maitland Street, Narrabri NSW 2390",
        postalAddress: "PO Box 323 Narrabri NSW 2390",
        phone: "1300 679 272",
    },
    {
        location: "Narrandera",
        streetAddress: "Cnr Cadell & Larmer Streets, Narrandera NSW 2700",
        postalAddress: "PO Box 13 Narrandera NSW 2700",
        phone: "6982 7881",
    },
    {
        location: "Narromine",
        streetAddress: "47 Merilba Street, Narromine NSW 2821",
        postalAddress: "Dubbo Court PO Box 50 Dubbo NSW 2830",
        phone: "1300 679 272",
    },
    {
        location: "Newcastle",
        streetAddress: "343 Hunter Street, Newcastle NSW 2300",
        postalAddress: "PO Box 388, Newcastle NSW 2300",
        phone: "1300 679 272",
    },
    {
        location: "Newtown",
        streetAddress: "222 Australia Street, Newtown NSW 2042",
        postalAddress: "PO Box 575 Newtown NSW 2042",
        phone: "1300 679 272",
    },
    {
        location: "North Sydney",
        streetAddress: "94 Pacific Highway, NORTH SYDNEY NSW 2059",
        postalAddress: "PO Box 19 North Sydney NSW 2059",
        phone: "1300 679 272",
    },
    {
        location: "Nowra",
        streetAddress: "88 Plunkett Street, Nowra NSW 2541",
        postalAddress: "PO Box 579 Nowra NSW 2541",
        phone: "1300 679 272",
    },
    {
        location: "Nyngan",
        streetAddress: "77 Cobar Street, Nyngan NSW 2825",
        postalAddress: "C/O Dubbo Court PO Box 50 Dubbo NSW 2830",
        phone: "1300 679 272",
    },
    {
        location: "Oberon",
        streetAddress: "74 Queen Street, Oberon NSW 2787",
        postalAddress: "PO Box 102 Oberon NSW 2787",
        phone: "6336 7600",
    },
    {
        location: "Orange",
        streetAddress: "Cnr Lords Place & Byng Street, Orange NSW 2800",
        postalAddress: "PO Box 97 Orange NSW 2800",
        phone: "1300 679 272",
    },
    {
        location: "Parkes",
        streetAddress: "37 Currajong Street, Parkes NSW 2870",
        postalAddress: "PO Box 131 Parkes NSW 2870",
        phone: "6862 9400",
    },
    {
        location: "Parramatta",
        streetAddress: "12 George Street, PARRAMATTA NSW 2150",
        postalAddress: "PO Box 92 Parramatta NSW 2124",
        phone: "1300 679 272",
    },
    {
        location: "Parramatta Childrens",
        streetAddress: "2 George Street, PARRAMATTA NSW 2150",
        postalAddress: "Locked Bag 5113 Parramatta NSW 2123",
        phone: "8688 1888",
    },
    {
        location: "Peak Hill",
        streetAddress: "80 Derribong Street, Peak Hill NSW 2869",
        postalAddress: "C/- Parkes Court House PO Box 131 Parkes NSW 2870",
        phone: "6862 9480",
    },
    {
        location: "Penrith",
        streetAddress: "64-72 Henry Street, Penrith NSW 2750",
        postalAddress: "PO Box 318 Penrith NSW 2751",
        phone: "1300 679 272",
    },
    {
        location: "Picton",
        streetAddress: "88 Argyle Street, Picton NSW 2571",
        postalAddress: "PO Box 209 Picton NSW 2571",
        phone: "1300 679 272",
    },
    {
        location: "Port Kembla",
        streetAddress: "Darcy Road, Port Kembla NSW 2505",
        postalAddress: "PO Box 87 Port Kembla NSW 2505",
        phone: "1300 679 272",
    },
    {
        location: "Port Macquarie",
        streetAddress: "24-28 Murray Street, Port Macquarie NSW 2444",
        postalAddress: "PO Box 6 Port Macquarie NSW 2444",
        phone: "1300 679 272",
    },
    {
        location: "Queanbeyan",
        streetAddress: "2 Farrer Place, Queanbeyan NSW 2620",
        postalAddress: "2 Farrer Place Queanbeyan NSW 2620",
        phone: "1300 679 272",
    },
    {
        location: "Quirindi",
        streetAddress: "George Street, Quirindi NSW 2343",
        postalAddress: "PO Box 347 Tamworth NSW 2340",
        phone: "1300 679 272",
    },
    {
        location: "Raymond Terrace",
        streetAddress: "Cnr William & Adelaide Streets, Raymond Terrace NSW 2324",
        postalAddress: "PO Box 596 Raymond Terrace NSW 2324",
        phone: "1300 679 272",
    },
    {
        location: "Richmond",
        streetAddress: "288 Windsor Street, RICHMOND NSW 2753",
        postalAddress: "PO Box 9 Richmond NSW 2753",
        phone: "1300 679 272",
    },
    {
        location: "Ryde",
        streetAddress: "814 Victoria Road, Ryde NSW 2112",
        postalAddress: "C/- Parramatta Local Court PO Box 92 Parramatta NSW 2124",
        phone: "1300 679 272",
    },
    {
        location: "Rylstone",
        streetAddress: "Louee Street, RYLSTONE NSW 2849",
        postalAddress: "PO Box 101 Rylstone NSW 2849",
        phone: "6371 5740",
    },
    {
        location: "Scone",
        streetAddress: "Cnr Liverpool and Main Streets, Scone NSW 2337",
        postalAddress: "PO Box 251 Scone NSW 2337",
        phone: "1300 679 272",
    },
    {
        location: "Singleton",
        streetAddress: "39 Elizabeth Street, Singleton NSW 2330",
        postalAddress: "PO Box 6 Singleton NSW 2330",
        phone: "6571 7200",
    },
    {
        location: "State Coroners",
        streetAddress: "1A Main Avenue, Lidcombe NSW 2141",
        postalAddress: "1A Main Avenue, Lidcombe NSW",
        phone: "8584 7777",
    },
    {
        location: "Surry Hills Children’s",
        streetAddress: "66-78 Albion Street, Surry Hills NSW 2010",
        postalAddress: "PO Box K303 Haymarket NSW 1240",
        phone: "8667 2100",
    },
    {
        location: "Sutherland",
        streetAddress: "Cnr Flora and Belmont Streets, Sutherland NSW 2232",
        postalAddress: "PO Box 37 Sutherland NSW 2232",
        phone: "1300 679 272",
    },
    {
        location: "Sydney Civil registry",
        streetAddress: "Level 4, John Maddison Tower, 86 Goulburn St, Sydney NSW 2000",
        postalAddress: "PO Box K1026 Haymarket NSW 2000",
        phone: "1300 679 272",
    },
    {
        location: "Tamworth",
        streetAddress: "Cnr Marius and Fitzroy Streets, Tamworth NSW 2340",
        postalAddress: "PO Box 347 Tamworth NSW 2340",
        phone: "1300 679 272",
    },
    {
        location: "Taree",
        streetAddress: "85 Albert Street, Taree NSW 2430",
        postalAddress: "PO Box 114 Taree NSW 2430",
        phone: "1300 679 272",
    },
    {
        location: "Temora",
        streetAddress: "154 De Boos Street, Temora NSW 2666",
        postalAddress: "PO Box 160 Temora NSW 2666",
        phone: "6982 7810",
    },
    {
        location: "Tenterfield",
        streetAddress: "94 Molesworth Street, Tenterfield NSW 2372",
        postalAddress: "PO Box  91 Armidale NSW 2350",
        phone: "6759 2390",
    },
    {
        location: "Toronto",
        streetAddress: "140-142 Cary Street, Toronto NSW 2283",
        postalAddress: "PO Box 994 Toronto NSW 2283",
        phone: "1300 679 272",
    },
    {
        location: "Tumbarumba",
        streetAddress: "23 Bridge Street, Tumbarumba NSW 2653",
        postalAddress: "PO Box 33 Tumbarumba NSW 2653",
        phone: "1300 679 272",
    },
    {
        location: "Tumut",
        streetAddress: "Cnr Wynyard and Fitzroy Streets, Tumut NSW 2720",
        postalAddress: "PO Box 39 Tumut NSW 2720",
        phone: "6981 3800",
    },
    {
        location: "Tweed Heads",
        streetAddress: "50-52 Recreation Street, Tweed Heads NSW 2485",
        postalAddress: "PO Box 171 Tweed Heads NSW 2485",
        phone: "1300 679 272",
    },
    {
        location: "Wagga Wagga",
        streetAddress: "57-77 Fitzmaurice St, WAGGA WAGGA NSW 2650",
        postalAddress: "PO Box 5862 Wagga Wagga NSW 2650",
        phone: "1300 679 272",
    },
    {
        location: "Walcha",
        streetAddress: "Apsley Street Walcha NSW 2354",
        postalAddress: "PO Box 91 Armidale NSW 2350",
        phone: "1300 679 272",
    },
    {
        location: "Walgett",
        streetAddress: "66 Wee Waa Street, Walgett NSW 2832",
        postalAddress: "PO Box 29 Walgett NSW 2832",
        phone: "6832 5880",
    },
    {
        location: "Warialda",
        streetAddress: "Stephens Street, Warialda NSW 2402",
        postalAddress: "PO Box 23 Inverell NSW 2360",
        phone: "1300 679 272",
    },
    {
        location: "Warren",
        streetAddress: "174 Dubbo Street, Warren NSW 2824",
        postalAddress: "C/O Dubbo Court PO Box 50 Dubbo NSW 2830",
        phone: "1300 679 272",
    },
    {
        location: "Wauchope",
        streetAddress: "41 Hastings Street, Wauchope NSW 2444",
        postalAddress: "PO Box 6 Port Macquarie NSW 2444",
        phone: "1300 679 272",
    },
    {
        location: "Waverley",
        streetAddress: "151 Bronte Road, Waverley NSW 2024",
        postalAddress: "PO Box 134 Waverley NSW 2024",
        phone: "1300 679 272",
    },
    {
        location: "Wee Waa",
        streetAddress: "Cnr Rose and Nelson Streets, Wee Waa NSW 2388",
        postalAddress: "PO Box 323 Narrabri NSW 2390",
        phone: "1300 679 272",
    },
    {
        location: "Wellington",
        streetAddress: "23 Maughan Street, WELLINGTON NSW 2820",
        postalAddress: "PO Box 77 Wellington NSW 2820",
        phone: "(02) 6862 9420",
    },
    {
        location: "Wentworth",
        streetAddress: "72 Darling Street, WENTWORTH NSW 2648",
        postalAddress: "C/- Broken Hill Court House, PO Box 260 Broken Hill NSW 2880",
        phone: "1300 679 272",
    },
    {
        location: "West Wyalong",
        streetAddress: "Court Street, West Wyalong NSW 2671",
        postalAddress: "PO Box 139 West Wyalong NSW 2671",
        phone: "6962 0831",
    },
    {
        location: "Wilcannia",
        streetAddress: "Reid Street, WILCANNIA NSW 2836",
        postalAddress: "C/- Broken Hill Court House, Po Box 260, Broken Hill NSW 2880",
        phone: "1300 679 272",
    },
    {
        location: "Windsor",
        streetAddress: "Cnr Court and Pitt Streets, WINDSOR NSW 2756",
        postalAddress: "PO Box 5 Windsor NSW 2756",
        phone: "1300 679 272",
    },
    {
        location: "Wollongong",
        streetAddress: "Cnr Church and Market Streets, WOLLONGONG NSW 2500",
        postalAddress: "PO Box 5395 Wollongong NSW 2500",
        phone: "1300 679 272",
    },
    {
        location: "Woy Woy",
        streetAddress: "78-80 Blackwall Road, Woy Woy NSW 2256",
        postalAddress: "PO Box 1 Woy Woy NSW 2256",
        phone: "4344 0111",
    },
    {
        location: "Wyong",
        streetAddress: "6 Anzac Avenue, Wyong NSW 2259",
        postalAddress: "PO Box 109 Wyong 2259",
        phone: "1300 679 272",
    },
    {
        location: "Yass",
        streetAddress: "47 Rossi Street, Yass NSW 2582",
        postalAddress: "C/- Goulburn Local Court PO Box 474 Goulburn NSW 2580",
        phone: "1300 679 272",
    },
    {
        location: "Young",
        streetAddress: "68 - 70 Lynch Street, Young NSW 2594",
        postalAddress: "PO Box 47 Young NSW 2594",
        phone: "6982 7860",
    },
]

export default NSWCourtRegistries

export enum DocumentType {
    LetterOfDemand = "LetterOfDemand",
    PreLitigationLetterOfDemand = "PreLitigationLetterOfDemand",
    StatementOfClaim = "StatementOfClaim",
    SettlementAndReleaseAgreement = "SettlementAndReleaseAgreement",
    LoanAgreement = "LoanAgreement",
    LoanAgreementWithSecurity = "LoanAgreementWithSecurity",
    PromissoryNote = "PromissoryNote",
    Unknown = "Unknown",
}

export enum DocumentStatus {
    Ready = "Ready",
    Delivered = "Delivered",
    Failed = "Failed",
}

export interface Document {
    id: string
    name: string
    recipient: string
    filename: string
    orgId: string
    type: DocumentType
    status: DocumentStatus
    createdAt: Date
    deliveredAt: Date | null
}

// Map template id to document type
export const getDocumentType = (templateId: string) => {
    switch (templateId) {
        case "letter-of-demand":
            return DocumentType.LetterOfDemand
        case "pre-litigation-letter-of-demand":
            return DocumentType.PreLitigationLetterOfDemand
        case "statement-of-claim":
            return DocumentType.StatementOfClaim
        case "settlement-and-release-agreement":
            return DocumentType.SettlementAndReleaseAgreement
        case "loan-agreement":
            return DocumentType.LoanAgreement
        case "loan-agreement-with-security":
            return DocumentType.LoanAgreementWithSecurity
        case "promissory-note":
            return DocumentType.PromissoryNote
        default:
            return DocumentType.Unknown
    }
}

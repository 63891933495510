// React
import { useState, useEffect } from "react"

// Material UI
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { FormHelperText } from "@mui/material"

// Contexts
import { useAuth } from "../context/AuthContext"

// Types
import { Document, DocumentType } from "../types/Document"
import { formatDate } from "../lib/DateUtils"

const API_KEY = process.env.REACT_APP_API_KEY

interface Props {
    label?: string
    documentType: DocumentType | null
    defaultDocumentID: string | null
    // eslint-disable-next-line no-unused-vars
    onChange: (selectedDocumentID: string) => void
    isRequired: boolean
}

const DocumentReferenceSelect = ({
    label,
    documentType,
    defaultDocumentID,
    onChange,
    isRequired,
}: Props) => {
    const auth = useAuth()
    const [loading, setLoading] = useState(true)
    const [documents, setDocuments] = useState<Document[]>([])

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            // onChange(null)
            var resp
            if (documentType !== null && documentType !== undefined) {
                resp = await fetch(`/api/documents?documentType=${documentType}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Api-Key ${API_KEY}`,
                    },
                })
            } else {
                resp = await fetch("/api/documents", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Api-Key ${API_KEY}`,
                    },
                })
            }
            if (resp.status === 200) {
                try {
                    const documents = (await resp.json()) as Document[]
                    setDocuments(documents)
                } catch (error) {
                    setDocuments([])
                    console.log(error)
                }
            }
            setLoading(false)
        })()
    }, [auth.loading, auth.activeTenant])

    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value)
    }

    // Format the document reference to be displayed in the select
    const getFormattedDocumentReference = (document: Document) => {
        return `${document.name} to ${document.recipient} dated ${formatDate(document.createdAt)}`
    }

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="document-reference-select-label" required={isRequired}>
                    {label ?? "Document"}
                </InputLabel>
                <Select
                    labelId="document-reference-select-label"
                    id="document-reference-select"
                    label={label ?? "Document"}
                    defaultValue={defaultDocumentID || ""}
                    onChange={handleChange}
                >
                    {loading ? (
                        <MenuItem value="0">Loading...</MenuItem>
                    ) : documents.length > 0 ? (
                        documents.map((i: Document) => (
                            <MenuItem key={i.id} value={getFormattedDocumentReference(i)}>
                                {getFormattedDocumentReference(i)}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="0">No documents found</MenuItem>
                    )}
                </Select>
                <FormHelperText>Select a document reference</FormHelperText>
            </FormControl>
        </Box>
    )
}

export default DocumentReferenceSelect

// React
import { useEffect, useState } from "react"

// Material UI
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { FormHelperText } from "@mui/material"

// Types
import { Address } from "xero-node"

const hashAddress = (address: Address) => {
    return `${address.addressLine1}${address.addressLine2}${address.addressLine3}${address.addressLine4}${address.city}${address.region}${address.postalCode}${address.country}`
}

type AddressHashTable = { [key: string]: Address }

interface Props {
    addresses: Address[] | null
    // eslint-disable-next-line no-unused-vars
    onChange: (selectedAddress: Address | null) => void
    isRequired: boolean
}

const AddressSelect = ({ addresses, onChange, isRequired }: Props) => {
    const [addressHashTable, setAddressHashTable] = useState<AddressHashTable | null>(null)

    const [shouldShowManualEntry, setShouldShowManualEntry] = useState(false)

    const [addressLine1, setAddressLine1] = useState("")
    const [addressLine2, setAddressLine2] = useState("")
    const [city, setCity] = useState("")
    const [region, setRegion] = useState("")
    const [postalCode, setPostalCode] = useState("")

    useEffect(() => {
        if (addresses) {
            const hashTable: AddressHashTable = {}
            addresses.forEach((address) => {
                hashTable[hashAddress(address)] = address
            })
            setAddressHashTable(hashTable)
        }
    }, [addresses])

    const handleSelection = (event: SelectChangeEvent) => {
        if (event.target.value === "other") {
            setShouldShowManualEntry(true)
            onChange(null)
        } else {
            setShouldShowManualEntry(false)
            const address = addressHashTable ? addressHashTable[event.target.value] : null
            onChange(address)
        }
    }

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        switch (name) {
            case "addressLine1":
                setAddressLine1(value)
                break
            case "addressLine2":
                setAddressLine2(value)
                break
            case "city":
                setCity(value)
                break
            case "region":
                setRegion(value)
                break
            case "postalCode":
                setPostalCode(value)
                break
            default:
                break
        }

        const address = {
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            region: region,
            postalCode: postalCode,
        } as Address

        onChange(address)
    }

    const shouldDisableAddresses = Object.keys(addresses ?? {}).length === 0

    // Fallback string if `str` value is null, undefined or an empty string.
    const showFallback = (str: string | null | undefined) => {
        if (str == null || str == undefined || str.length === 0) {
            return "Unknown"
        }
        return str
    }

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth disabled={shouldDisableAddresses}>
                <InputLabel id="address-select-label" required={isRequired}>
                    Address
                </InputLabel>
                <Select
                    labelId="address-select-label"
                    id="address-select"
                    // value={defaultKey?.toString()}
                    label="Address"
                    onChange={handleSelection}
                >
                    {Object.entries(addressHashTable ?? {}).map(([key, address]) => {
                        return (
                            <MenuItem key={key} value={key}>
                                {address.addressType}: {showFallback(address.addressLine1)} -{" "}
                                {showFallback(address.city)} - {showFallback(address.region)}
                            </MenuItem>
                        )
                    })}
                    <MenuItem value={"other"}>
                        <b>Other - enter manually</b>
                    </MenuItem>
                </Select>
                <FormHelperText>Select the address</FormHelperText>
            </FormControl>

            {shouldShowManualEntry && (
                <Box sx={{ mt: 2, p: 2, border: "1px solid grey" }}>
                    <Stack spacing={1}>
                        <TextField
                            name="addressLine1"
                            id="address-line-1"
                            label="Address Line 1"
                            variant="outlined"
                            size="small"
                            required={isRequired}
                            onChange={handleAddressChange}
                        />
                        <TextField
                            name="addressLine2"
                            id="address-line-2"
                            label="Address Line 2"
                            variant="outlined"
                            size="small"
                            required={false}
                            onChange={handleAddressChange}
                        />

                        <Stack direction="row" spacing={1}>
                            <TextField
                                name="city"
                                id="address-city"
                                label="Suburb"
                                variant="outlined"
                                size="small"
                                required={isRequired}
                                onChange={handleAddressChange}
                            />

                            <TextField
                                name="region"
                                id="address-region"
                                label="State"
                                variant="outlined"
                                size="small"
                                required={isRequired}
                                onChange={handleAddressChange}
                            />

                            <TextField
                                name="postalCode"
                                id="address-postal-code"
                                label="Postcode"
                                variant="outlined"
                                size="small"
                                required={isRequired}
                                onChange={handleAddressChange}
                            />
                        </Stack>
                    </Stack>
                </Box>
            )}
        </Box>
    )
}

export default AddressSelect

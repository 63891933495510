// React
import { useState, forwardRef, useCallback } from "react"

// Material UI
import Collapse from "@mui/material/Collapse"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { CircularProgress } from "@mui/material"
import { styled } from "@mui/material/styles"

// Components
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack"

const API_KEY = process.env.REACT_APP_API_KEY

// Custom styles
const StyledSnackbarContent = styled(SnackbarContent)({
    "@media (min-width:600px)": {
        minWidth: "344px !important",
    },
})
const StyledCard = styled(Card)({
    backgroundColor: "#4caf50",
    width: "100%",
})
const StyledTypography = styled(Typography)({
    color: "#000",
})
const StyledCardActions = styled(CardActions)({
    padding: "8px 8px 8px 16px",
    justifyContent: "space-between",
})
const StyledIconContainer = styled("div")({
    marginLeft: "auto",
})
const StyledExpand = styled(IconButton)({
    padding: "8px 8px",
    transform: "rotate(0deg)",
    color: "#000",
    transition: "all .2s",
})
const StyledExpandOpen = styled(IconButton)({
    transform: "rotate(180deg)",
})
const StyledPaper = styled(Paper)({
    backgroundColor: "#fff",
    padding: 16,
})
const StyledCheckIcon = styled(CheckCircleIcon)({
    fontSize: 20,
    paddingRight: 4,
})
const StyledButton = styled(Button)({
    padding: 0,
    textTransform: "none",
})

interface DocumentCompleteProps extends CustomContentProps {
    documentId: string
}

const DocumentComplete = forwardRef<HTMLDivElement, DocumentCompleteProps>(
    ({ id, ...props }, ref) => {
        const { closeSnackbar } = useSnackbar()
        const [expanded, setExpanded] = useState(false)
        const [downloading, setDownloading] = useState(false)

        const handleExpandClick = useCallback(() => {
            setExpanded((oldExpanded) => !oldExpanded)
        }, [])

        const handleDismiss = useCallback(() => {
            closeSnackbar(id)
        }, [id, closeSnackbar])

        const handleDownload = useCallback(async () => {
            setDownloading(true)
            const resp = await fetch(`/api/documents/${props.documentId}/export`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Api-Key ${API_KEY}`,
                },
            })

            if (resp.status === 200) {
                const response = await resp.json()
                console.log(response.signedUrl)
                window.open(response.signedUrl, "_blank")
            }

            setDownloading(false)
        }, [props.documentId])

        return (
            <StyledSnackbarContent ref={ref}>
                <StyledCard>
                    <StyledCardActions>
                        <StyledTypography variant="body2">{props.message}</StyledTypography>
                        <StyledIconContainer>
                            <StyledExpandOpen
                                aria-label="Show more"
                                size="small"
                                onClick={handleExpandClick}
                            >
                                <ExpandMoreIcon />
                            </StyledExpandOpen>
                            <StyledExpand size="small" onClick={handleDismiss}>
                                <CloseIcon fontSize="small" />
                            </StyledExpand>
                        </StyledIconContainer>
                    </StyledCardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <StyledPaper>
                            <Typography
                                gutterBottom
                                variant="caption"
                                style={{ color: "#000", display: "block" }}
                            >
                                PDF ready
                            </Typography>
                            <StyledButton size="small" color="primary" onClick={handleDownload}>
                                {downloading ? (
                                    <>
                                        <CircularProgress
                                            style={{ width: 20, height: 20, marginRight: 4 }}
                                        />
                                        Downloading...
                                    </>
                                ) : (
                                    <>
                                        <StyledCheckIcon />
                                        Download now
                                    </>
                                )}
                            </StyledButton>
                        </StyledPaper>
                    </Collapse>
                </StyledCard>
            </StyledSnackbarContent>
        )
    },
)

DocumentComplete.displayName = "DocumentComplete"

export default DocumentComplete

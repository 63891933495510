// Material UI
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { FormHelperText } from "@mui/material"

// Types
import CourtRegistry from "../types/CourtRegistry"

interface Props {
    label?: string
    registries: CourtRegistry[]
    defaultRegistry: string | null
    // eslint-disable-next-line no-unused-vars
    onChange: (selectedRegistry: CourtRegistry | null) => void
    isRequired: boolean
}

const CourtRegistrySelect = ({
    label,
    registries,
    defaultRegistry,
    onChange,
    isRequired,
}: Props) => {
    const handleChange = (event: SelectChangeEvent) => {
        onChange(registries.find((r) => r.location === event.target.value) || null)
    }

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="court-registry-select-label" required={isRequired}>
                    {label ?? "Registry"}
                </InputLabel>
                <Select
                    labelId="court-registry-select-label"
                    id="court-registry-select"
                    label={label ?? "Registry"}
                    defaultValue={defaultRegistry || ""}
                    onChange={handleChange}
                >
                    {registries.map((i) => (
                        <MenuItem key={i.location} value={i.location}>
                            {i.location}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Select a NSW Court Registry</FormHelperText>
            </FormControl>
        </Box>
    )
}

export default CourtRegistrySelect

import { Collapse, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Alert } from "@mui/material"

import { useEffect, useState } from "react"

const XERO_DATA_WARNING_ALERT_DISMISSED = "xeroDataWarningAlertDismissed"

export default function DismissibleXeroDataWarning() {
    const [showWarningAlert, setShowWarningAlert] = useState(false)

    useEffect(() => {
        const utcDismissDateString = localStorage.getItem(XERO_DATA_WARNING_ALERT_DISMISSED)

        if (utcDismissDateString === null || utcDismissDateString === undefined) {
            return setShowWarningAlert(true)
        }

        const xeroDataWarningAlertDismissedDate = new Date(utcDismissDateString)

        if (xeroDataWarningAlertDismissedDate > new Date(Date.now() - 1000 * 60 * 60 * 24 * 7)) {
            setShowWarningAlert(false)
        } else {
            setShowWarningAlert(true)
        }
    }, [])

    const handleDismiss = () => {
        localStorage.setItem(XERO_DATA_WARNING_ALERT_DISMISSED, new Date().toUTCString())
        setShowWarningAlert(false)
    }

    return (
        <Collapse in={showWarningAlert}>
            <Alert
                sx={{ mt: 2 }}
                severity="warning"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleDismiss}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                Law Off the Shelf relies on the accuracy and completeness of the data stored in your
                Xero account. While we make every effort to find missing information and prompt you
                to provide it, if your contacts and invoices in Xero have missing or incorrect
                addresses, ABNs/ACNs or other important information, then the documents generated by
                Law Off the Shelf may be incomplete or inaccurate.
            </Alert>
        </Collapse>
    )
}

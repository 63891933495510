// React
import { Fragment, useState, useEffect, useRef } from "react"

// Material UI
import {
    Container,
    Typography,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Button,
} from "@mui/material"
import { Close } from "@mui/icons-material"
import { styled } from "@mui/material/styles"

// Styles
import styles from "../styles/Home.module.css"
import { Stack } from "@mui/system"

interface FooterDocument {
    title: string
    content: React.ReactNode
}

const termsOfService: FooterDocument = {
    title: "Terms of Service",
    content: (
        <Fragment>
            <p>
                These Terms of Service (<strong>Terms</strong>) along with our{" "}
                <a href="" target="_blank">
                    Privacy Policy
                </a>{" "}
                affect your legal rights. This is an important document that you should read
                carefully.
            </p>
            <p>
                Law Off The Shelf is not a law firm and does not provide legal advice. Any
                information or documentation taken from or obtained from Law Off The Shelf
                constitutes general legal information only. That information is not be tailored to
                your specific circumstances.
            </p>
            <p>
                The document automation service provided by Law Off The Shelf uses existing data
                entered in your Xero accounting software, and the efficacy of that service relies on
                the accuracy of the data which you have recorded in your Xero accounting software.
                Law Off the Shelf accepts no liability for any loss arising from inaccurate
                information or data in documents created by Law Off The Shelf, where that inaccuracy
                arises as a consequence of the information or data recorded in your Xero accounting
                software.
            </p>
            <p>
                The Terms (as varied from time to time), along with any other policies (including
                the Privacy Policy) posted on our website at{" "}
                <a href="lawofftheshelf.com.au" target="_blank">
                    lawofftheshelf.com.au
                </a>{" "}
                (<strong>Website</strong>), form the whole agreement between us and you for your use
                of the Website. The Agreement supersedes any previous or contemporaneous agreements
                or communications, howsoever arising between you and us.
            </p>

            <h3>1. Introduction</h3>
            <p>
                The Website is provided Lext Australia Pty Ltd ABN 96 634 540 864 trading as Law Off
                the Shelf and its Related Bodies Corporate (<strong>we, our, us</strong>).
            </p>
            <p>
                By signing up and using our Website (howsoever access is made) or accessing any of
                our content, you agree to be legally bound to these Terms. These Terms apply to all
                users of the site, including without limitation users who are browsers, customers
                and / or contributors of content (each, a <strong>User</strong>). In addition, you
                agree that the Privacy Policy applies to your use of the Website.
            </p>
            <p>
                Please read these Terms carefully before accessing or using our Website. By
                accessing or using any part of the Website, you agree to be bound by these Terms. If
                you do not agree to all the terms and conditions of this agreement, then you may not
                access the Website. If these Terms are considered an offer, acceptance is expressly
                limited to these Terms.
            </p>

            <h3>2. Users and User Information</h3>
            <p>You, as a User, agree, acknowledge and represent that:</p>
            <ol>
                <li>
                    you have read and understood the Terms and each other document forming the
                    Agreement and have the capacity to enter into the Agreement;
                </li>
                <li>
                    you may not, and will not use the Website for any illegal or unauthorised
                    purpose;
                </li>
                <li>
                    you will not transmit any harmful code, viruses, worms, trojans or attempt to
                    access areas of the Website which you have not been given access to by us;
                </li>
                <li>
                    we may refuse service and/or the use of the Website to anyone at any time in our
                    absolute, unfettered discretion, without giving reasons;
                </li>
                <li>
                    we have the right to modify and update the content on our Website at any time,
                    and it is your responsibility to monitor the Website for any changes;
                </li>
                <li>
                    insofar as information is provided on the Website, we give no warranties as to
                    the accuracy or completeness of any such information.
                </li>
            </ol>

            <h3>3. Personal Information and Confidential Information</h3>
            <p>
                Your submission of Personal Information through the Website is governed by our
                Privacy Policy. To view our Privacy Policy, please click{" "}
                <a href="" target="_blank">
                    here
                </a>
                .
            </p>
            <p>
                We shall not, and you agree that you will not, disclose to any person or entity, or
                permit to be disclosed any Confidential Information except for disclosures:
            </p>
            <ol>
                <li>
                    authorised in writing by the party whose Confidential Information is in
                    question;
                </li>
                <li>by us, to our contractors, advisers and agents; and</li>
                <li>as required by law.</li>
            </ol>
            <p>
                The restriction on disclosure shall not apply if such information is ascertainable
                from the public domain, published information from trade sources by non-default of
                any party.
            </p>

            <h3>4. Prohibited Use</h3>
            <p>
                In addition to other prohibitions as set forth in the Terms, you are prohibited from
                using the Website:
            </p>
            <ol>
                <li>for any unlawful purpose;</li>
                <li>to solicit others to perform or participate in any unlawful acts;</li>
                <li>
                    to violate any international, federal, provincial or state regulations, rules,
                    laws, or local ordinances;{" "}
                </li>
                <li>
                    to infringe upon or violate our Intellectual Property Rights or the Intellectual
                    Property Rights of others;{" "}
                </li>
                <li>
                    to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or
                    discriminate based on gender, sexual orientation, religion, ethnicity, race,
                    age, national origin, or disability;{" "}
                </li>
                <li>to submit false or misleading information; </li>
                <li>
                    to upload or transmit viruses or any other type of malicious code that will or
                    may be used in any way that will affect the functionality or operation of the
                    Website or of any related website, other websites, or the Internet;{" "}
                </li>
                <li>to collect or track the personal information of others; </li>
                <li>to spam, phish, pharm, pretext, spider, crawl, or scrape; </li>
                <li>for any obscene or immoral purpose; or </li>
                <li>
                    to interfere with or circumvent the security features of the Website or any
                    related website, other websites, or the Internet.{" "}
                </li>
            </ol>
            <p>
                We reserve the right to terminate your use of the Website or any related website for
                violating any of the prohibited uses.
            </p>

            <h3>5. Third Party Links and Content</h3>
            <p>
                Certain content, products and services available via the Website may include
                materials from third-parties (<strong>Third-Party Material</strong>) or links to
                third party sites or locations (<strong>Third-Party Links</strong>).
            </p>
            <p>
                Third-Party Links on our Website may direct you to third-party websites that are not
                affiliated with us. In addition, we may incorporate Third-Party Material into our
                Website. In the event that we incorporate Third-Party Material into the Website, we
                will identify the source of that Third-Party Material to you. We are not responsible
                for examining or evaluating the content or accuracy and we do not warrant and will
                not have any liability or responsibility for any Third-Party Material or Third-Party
                Websites, or for any other materials, products, or services of third-parties.
            </p>
            <p>
                We are not liable for any harm or damages related to the purchase or use of goods,
                services, resources, content, or any other transactions made in connection with any
                Third-Party Websites or Third-Party Materials. Please review carefully the
                third-party's policies and practices and make sure you understand them before you
                engage in any transaction. Complaints, claims, concerns, or questions in this regard
                should be directed to the third-party.
            </p>

            <h3>6. Legal Documents Created by the Website</h3>
            <p>
                Any completed legal document created and provided to you through your use of the
                Website is provided to you subject to a non-transferable limited licence (with no
                right to sublicense) for personal use of that document only. For the avoidance of
                doubt, no licence is granted to you in respect of any template used to create your
                legal document(s).{" "}
            </p>
            <p>
                You may not redistribute, resell or otherwise infringe upon our Intellectual
                Property Rights in relation to any legal documents created and provided to you
                through your use of the Website.{" "}
            </p>

            <h3>7. Community and Feedback</h3>
            <p>
                If, at our request, you send us information or materials, or without request from us
                you send creative ideas, suggestions, proposals, plans, or other materials
                (howsoever transmitted) (<strong>Feedback</strong>), you agree that we may, at any
                time, without restriction, edit, copy, publish, distribute, translate and otherwise
                use in any medium any Feedback that you provide to us. In the event that you provide
                us with Feedback, we are under no obligation to maintain that Feedback in
                confidence, respond to that Feedback or pay compensation for the Feedback or in any
                manner in which we may use it.{" "}
            </p>
            <p>
                We may, but have no obligation to, monitor, edit or remove content that we determine
                in our sole discretion is unlawful, offensive, threatening, libellous, defamatory,
                pornographic, obscene or otherwise objectionable or violates any person’s
                Intellectual Property Rights.
            </p>
            <p>
                If you provide Feedback to us, you agree that your Feedback will not violate any
                rights of any person, including any Intellectual Property Rights. You may not
                provide us with any offensive Feedback or Feedback which is libellous, slanderous or
                otherwise lawful, abusive or obscene, or which might otherwise contain any harmful
                code, virus or malware.{" "}
            </p>
            <p>
                You are solely responsible for any Feedback you provide and we take no
                responsibility and assume no liability for Feedback posted or provided by you or any
                third-party.
            </p>

            <h3>8. Errors and Omissions</h3>
            <p>
                Occasionally there may be information on our Website that contains typographical
                errors, inaccuracies or omissions that may relate to product descriptions, pricing,
                promotions, offers and availability. We reserve the right to correct any errors,
                inaccuracies or omissions, and to change or update information, suspend the Website
                or cancel any order if any information on the Website or on any related website is
                inaccurate at any time without prior notice.
            </p>
            <p>
                We undertake no obligation to update, amend or clarify information on the Website or
                on any related website, including without limitation, pricing information, except as
                required by law.{" "}
            </p>

            <h3>9. Disclaimers and Limitation of Liability</h3>
            <ol>
                <li>
                    Your use of, or inability to use, the Website and its content is at your sole
                    risk. The Website and its content and all products and services delivered to you
                    through the Website are (except as expressly stated by us) provided 'as is' and
                    'as available' for your use, without any representation, warranties or
                    conditions of any kind, either express or implied, including all implied
                    warranties or conditions of merchantability, merchantable quality, fitness for a
                    particular purpose, durability, title, and non-infringement.
                </li>
                <li>
                    To the maximum extent permitted by law, we, our directors, officers, employees,
                    affiliates, agents, contractors, suppliers, service providers or licensors shall
                    not be liable for any injury, loss, claim, or any direct, indirect, incidental,
                    punitive, special, or consequential damages of any kind, including, without
                    limitation lost profits, lost revenue, lost savings, loss of data, replacement
                    costs, or any similar damages, whether based in contract, tort (including
                    negligence), strict liability or otherwise, arising from your use of the Website
                    or any products procured using the Website, or for any other claim related in
                    any way to your use of the service or any product, including, but not limited
                    to, any errors or omissions in any content, or any loss or damage of any kind
                    incurred as a result of the use of the Website or any content (or product)
                    posted, transmitted, or otherwise made available via the Website, even if
                    advised of their possibility.
                </li>
                <li>
                    Notwithstanding any other things set out in these Terms, any warranty or
                    guarantee which is incorporated into these Terms or arises in relation to the
                    Services by the operation of any law of Australia or a state of Australia is, to
                    the fullest extent permitted by law, excluded, or limited, as the case may be.
                </li>
            </ol>

            <h3>10. Force majeure</h3>
            <p>
                We will not be in breach of any obligations under these Terms or be liable for any
                loss where any such breach or loss is wholly or partially caused, whether directly
                or indirectly as a result of:{" "}
            </p>
            <ol>
                <li>
                    act of God, earthquake, cyclone, fire, explosion, flood, landslide, lightning,
                    storm, tempest, drought or meteor;{" "}
                </li>
                <li>
                    war (declared or undeclared), invasion, act of a foreign enemy, hostilities
                    between nations, civil insurrection or militarily usurped power;{" "}
                </li>
                <li>
                    act of public enemy, sabotage or malicious damage (other than any such act which
                    involves our personnel), terrorism or civil unrest;{" "}
                </li>
                <li>
                    ionising radiation or contamination by radioactivity from any nuclear waste or
                    from combustion of nuclear fuel;
                </li>
                <li>
                    confiscation, nationalisation, requisition, expropriation, prohibition, embargo,
                    restraint or damage to property by or under the order of any government or
                    government authority;{" "}
                </li>
                <li>
                    strikes, blockades, lock out or other industrial disputes other than an
                    industrial dispute that only involves the party's personnel; and/or{" "}
                </li>
                <li>any action or inaction on the part of the User.</li>
            </ol>

            <h3>11. Indemnity</h3>
            <p>
                You agree to indemnify us, defend us and hold us harmless, as well as our parent,
                subsidiaries, affiliates, partners, officers, directors, agents, contractors,
                licensors, service providers, subcontractors, suppliers, interns and employees,
                harmless from any claim or demand, including reasonable legal fees, made by any
                third-party due to or arising out of your breach of these Terms or the documents
                they incorporate by reference, or your violation of any law or the rights of a
                third-party.
            </p>

            <h3>12. Severability</h3>
            <p>
                In the event that any provision of these Terms is determined to be unlawful, void or
                unenforceable, such provision shall nonetheless be enforceable to the fullest extent
                permitted by applicable law, and the unenforceable portion shall be deemed to be
                severed from these Terms, such determination shall not affect the validity and
                enforceability of any other remaining provisions.
            </p>

            <h3>13. Termination</h3>
            <p>
                The obligations and liabilities of the parties incurred prior to the termination
                date shall survive the termination of this agreement for all purposes.
            </p>
            <p>
                These Terms are effective unless and until terminated by either you or us. You may
                terminate these Terms at any time by cancelling any account you have with us or
                otherwise notifying us that you no longer wish to use the Website.
            </p>
            <p>
                If in our absolute and unfettered discretion we determine that you have failed, or
                we suspect that you have failed, to comply with any term or provision of these
                Terms, we also may terminate this agreement at any time without notice and/or
                accordingly may deny you access to our Website (or any part thereof).
            </p>

            <h3>14. General</h3>
            <ol>
                <li>
                    <strong>Jurisdiction:</strong> The use of the Website and these Terms and
                    Conditions are subject to the laws of New South Wales and the Commonwealth of
                    Australia. Both we and the User submit to the non-exclusive jurisdiction of the
                    courts located in those jurisdictions.{" "}
                </li>
                <li>
                    <strong>Changes to Terms:</strong> We may amend these Terms from time to time by
                    making the amended terms available on the Website. If that happens, we will
                    notify Users of the changed Terms. Users will be deemed to have accepted the
                    Terms as amended after that time by accessing the Website or using the Services
                    after that time.
                </li>
                <li>
                    <strong>Assignment:</strong> We may assign this Agreement by notice in writing
                    to the User.
                </li>
                <li>
                    <strong>Notice:</strong> Notices under this Agreement may be given to you by the
                    email address you provide to us when signing up for the service.{" "}
                </li>
                <li>
                    <strong>Survival</strong> clauses 3, 4, 5, 11 and 13 survive any termination of
                    the Agreement.{" "}
                </li>
            </ol>

            <h3>15. Definitions</h3>
            <p>The following defined terms have the following meanings when used in these Terms:</p>
            <ol>
                <li>
                    <strong>Confidential Information</strong> means all information provided by a
                    party, in any form whatsoever (whether written, oral, electronically or
                    otherwise) which is marked or otherwise designated directly or indirectly as
                    'Confidential', 'Proprietary' or similar or which would otherwise customarily be
                    understood to be confidential by its nature;
                </li>
                <li>
                    <strong>Intellectual Property Rights</strong> means any copyright, registered or
                    pending patent or patentable invention, registered and unregistered trade mark,
                    confidential information, trade secrets, know-how, registered or registrable
                    design, design rights, inventions, rights in relation to any of the foregoing,
                    rights in the nature of valuable or potentially valuable information or
                    invention, such information, invention or product coming into existence through
                    the expenditure of labour or skill or effort or the investment of time or money,
                    and all other rights resulting from intellectual activity in the industrial,
                    scientific, technical, literary or artistic fields; and{" "}
                </li>
                <li>
                    <strong>Related Bodies Corporate</strong> has the meaning given to that term in
                    the Corporations Act 2001 (Cth).{" "}
                </li>
            </ol>
        </Fragment>
    ),
}

const privacyPolicy: FooterDocument = {
    title: "Privacy Policy",
    content: (
        <Fragment>
            <p>
                In this Privacy Policy, <strong>'us', 'we'</strong> or <strong>'our'</strong> means
                Lext Australia Pty Ltd ABN 96 634 540 864 trading as Law Off the Shelf.
            </p>
            <p>We are committed to respecting your privacy.</p>
            <p>
                In Australia, we are bound by the Australian Privacy Principles contained in the
                Privacy Act 1988 (Cth) (Privacy Act). Our Privacy Policy sets outs out how we
                collect, use, process, store and disclose your personal information. Personal
                information includes information or an opinion about an identified individual or an
                individual that is reasonably identifiable. For example, this may include your name,
                age, gender, postcode and contact details.
            </p>
            <p>
                We may change our Privacy Policy from time to time by publishing changes to it on
                our website. We encourage you to check our website periodically to ensure that you
                are aware of our current Privacy Policy.
            </p>

            <h3>What personal information do we collect?</h3>

            <p>We may collect the following types of personal information:</p>
            <ul>
                <li>name;</li>
                <li>mailing or street address;</li>
                <li>email address;</li>
                <li>telephone number and other contact details;</li>
                <li>
                    details from your employer (if we are dealing with you as a representative of
                    the business that employs you);
                </li>
                <li>age or date of birth;</li>
                <li>gender;</li>
                <li>credit card information;</li>
                <li>any information about you stored in your Xero account, if you have one;</li>
                <li>
                    your device ID, your device type, Internet browser and device operating system
                    details, geo-location information (including country and suburb name based on
                    this), computer and connection information, statistics on page views, traffic to
                    and from the sites, ad data, IP address and standard web log information;
                </li>
                <li>
                    details of the products and services we have provided to you (or the entity or
                    business that employs or engages you as a contractor) or that you have enquired
                    about, including any additional information necessary to deliver those products
                    and services and responses to your enquiries;
                </li>
                <li>
                    any additional information relating to you that you provide to us directly
                    through our website or app or indirectly through your use of our website or app
                    or online presence or through other websites or accounts from which you permit
                    us to collect information;
                </li>
                <li>information you provide to us through customer surveys; or</li>
                <li>
                    any other personal information that may be required in order to facilitate your
                    dealings with us.
                </li>
            </ul>
            <p>
                We may collect these types of personal information either directly from you, or from
                third parties. We may collect this information when you:
            </p>
            <ul>
                <li>register on our website;</li>
                <li>
                    communicate with us through correspondence, chats, email, or when you share
                    information with us from other social applications, services or websites; or
                </li>
                <li>interact with our sites, services, content, surveys and advertising.</li>
            </ul>

            <h3>Why do we collect, use, process and disclose personal information?</h3>

            <p>We will collect, hold, use, process and disclose your personal information to:</p>
            <ul>
                <li>enable you to access and use our website and/or services;</li>
                <li>
                    operate, protect, improve and optimise our website and services business and our
                    users' experience, such as to perform analytics, conduct research and for
                    advertising and marketing;
                </li>
                <li>
                    send you service, support and administrative messages, reminders, technical
                    notices, updates, security alerts, and information requested by you;
                </li>
                <li>
                    send you marketing and promotional messages and other information that may be of
                    interest to you, including information sent by, or on behalf of, our business
                    partners that we think you may find interesting;
                </li>
                <li>
                    administer rewards, surveys, contests, or other promotional activities or events
                    sponsored or managed by us or our business partners; and
                </li>
                <li>
                    comply with our legal obligations, resolve any disputes that we may have with
                    any of our users, and enforce our agreements with third parties.
                </li>
            </ul>
            <p>
                We may also disclose your personal information to a trusted third party who also
                holds other information about you. This third party may combine that information in
                order to enable it and us to develop anonymised consumer insights so that we can
                better understand your preferences and interests, personalise your experience and
                enhance the products and services that you receive.
            </p>

            <h3>Do we use your personal information for direct marketing?</h3>

            <p>
                We and/or our carefully selected business partners may send you direct marketing
                communications and information about our products and service if you have consented
                to receiving these communications. This may take the form of emails, SMS, mail or
                other forms of communication, in accordance with the Spam Act and the Privacy Act.
                You may opt-out of receiving marketing materials from us by contacting us at
                enquiries@lext.com.au, or by using the opt-out facilities provided in the
                communication.
            </p>

            <h3>To whom do we disclose your personal information?</h3>

            <p>
                We may disclose personal information for the purposes described in this Privacy
                Policy to:
            </p>
            <ul>
                <li>
                    our employees and to our related bodies corporate as listed at the top of the
                    Privacy Policy;
                </li>
                <li>
                    third party suppliers and service providers (including providers for the
                    operation of our websites and/or our business or in connection with providing
                    our products and services to you);
                </li>
                <li>professional advisers, dealers and agents;</li>
                <li>payment systems operators (e.g. merchants receiving card payments);</li>
                <li>our existing or potential agents, business partners or partners;</li>
                <li>
                    our sponsors or promoters of any competition that we conduct via our services;
                </li>
                <li>
                    anyone to whom our assets or businesses (or any part of them) are transferred;
                </li>
                <li>
                    specific third parties authorised by you to receive information held by us;
                    and/or
                </li>
                <li>
                    other persons, including government agencies, regulatory bodies and law
                    enforcement agencies, or as required, authorised or permitted by law.
                </li>
            </ul>

            <h3>Disclosure of Australian personal information outside Australia</h3>
            <p>
                We may disclose personal information outside of Australia to cloud providers and
                software providers located outside of Australia, including the United Kingdom,
                Ireland, Singapore and the United States of America. When you provide your personal
                information to us, you consent to the disclosure of your information outside of
                Australia and acknowledge that we are not required to ensure that overseas
                recipients handle that personal information in compliance with the Privacy Act. We
                will, however, take reasonable steps to ensure that any overseas recipient will deal
                with such personal information in a way that is consistent with the Australian
                Privacy Principles.
            </p>

            <h3>Websites and cookies</h3>

            <p>
                We may collect personal information about you when you use and access our website or
                a publisher's website that we (or a third party acting on our behalf) is placing
                advertising on.
            </p>
            <p>
                While we do not use browsing information to identify you personally, we may record
                certain information about your use of these websites, such as which pages you visit,
                the time and date of your visit and the internet protocol (IP) address assigned to
                your computer. We record your IP address in order to assist us to protect our
                systems from malicious activities, including denial of service attacks and brute
                force attempts to access our systems. We store IP addresses for this purpose for 30
                days in order to detect and analyse previous attacks on our systems.
            </p>
            <p>
                We (or a third party acting on our behalf) may also use 'cookies' or other similar
                tracking technologies on these website that help us track your website usage and
                remember your preferences. Cookies are small files that store information on your
                computer, TV, mobile phone or other device. They enable the entity that put the
                cookie on your device to recognise you across different websites, services, devices
                and/or browsing sessions. We (or a third party acting on our behalf) may use cookies
                to determine which advertisements to display to you on our website or a publisher's
                website that we (or a third party acting on our behalf) is placing advertising on.
                You can disable cookies through your internet browser but our websites may not work
                as intended for you if you do so.
            </p>
            <p>
                We may also use cookies to enable us to collect data that may include personal
                information. For example, where a cookie is linked to your account, it will be
                considered personal information under the Privacy Act and the General Data
                Protection Regulation (GDPR). We will handle any personal information collected by
                cookies in the same way that we handle all other personal information as described
                in this Privacy Policy.
            </p>

            <h3>Storage of personal data</h3>

            <p>
                We will store your personal data for up to 5 years after we have completed providing
                our services to you (or to the business that employs or engages you as a contractor,
                if applicable). After this time:
            </p>
            <ul>
                <li>
                    we will continue to store your personal data only to the extent required by any
                    law applicable to our business or for compliance and risk management purposes;
                    and
                </li>
                <li>
                    we will delete or de-identify your personal data when it is no longer necessary
                    or required to be kept.
                </li>
            </ul>

            <h3>Security</h3>

            <p>
                We may hold your personal information in either electronic or hard copy form. We
                take reasonable steps to protect your personal information from misuse, interference
                and loss, as well as unauthorised access, modification or disclosure and we use a
                number of physical, administrative, personnel and technical measures to protect your
                personal information.
            </p>

            <h3>Links</h3>

            <p>
                Our website may contain links to websites operated by third parties. Those links are
                provided for convenience and may not remain current or be maintained. Unless
                expressly stated otherwise, we are not responsible for the privacy practices of, or
                any content on, those linked websites, and have no control over or rights in those
                linked websites. The privacy policies that apply to those other websites may differ
                substantially from our Privacy Policy, so we encourage individuals to read them
                before using those websites.
            </p>
            <h3>Accessing or correcting your personal information</h3>
            <p>
                You can access the personal information we hold about you by contacting us using the
                contact information provided through the website or using the contact details set
                out below . Sometimes, we may not be able to provide you with access to all of your
                personal information and, where this is the case, we will tell you why. We may also
                need to verify your identity when you request your personal information.
            </p>
            <p>
                If you think that any personal information we hold about you is inaccurate, please
                contact us using the details set out below and we will take reasonable steps to
                ensure that it is corrected.
            </p>

            <h3>Making a complaint</h3>

            <p>
                If you think we have breached the Privacy Act, or you wish to make a complaint about
                the way we have handled your personal information, you can contact us at
                enquiries@lext.com.au. Please include your name, email address and/or telephone
                number and clearly describe your complaint. We will acknowledge your complaint and
                respond to you regarding your complaint within the time required by law (if
                applicable) or otherwise within a reasonable period of time, typically within 30
                days. If you think that we have failed to resolve the complaint satisfactorily, we
                will provide you with information about the further steps you can take.
            </p>

            <h3>Contact Us</h3>

            <p>
                For further information about our Privacy Policy or practices, or to access or
                correct your personal information, or make a complaint, please contact us using the
                details set out below:
            </p>
            <br />
            <p>David Turner</p>
            <p>Head of Operations</p>
            <p>Lext Australia Pty Ltd</p>
            <p>Level 3, 156 Clarence St, Sydney, 2000, Australia.</p>
            <p>enquiries@lext.com.au</p>
            <p>
                <em>Effective: 31 January 2023</em>
            </p>
        </Fragment>
    ),
}

interface DialogTitleProps {
    id: string
    children?: React.ReactNode
    onClose: () => void
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}))

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

export default function Footer() {
    const [open, setOpen] = useState(false)
    const [document, setDocument] = useState<FooterDocument | null>(null)

    const handleClickOpen = (document: FooterDocument) => () => {
        setOpen(true)
        setDocument(document)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const descriptionElementRef = useRef<HTMLElement>(null)

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    return (
        <Fragment>
            <BootstrapDialog
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                scroll={"paper"}
                aria-labelledby="customized-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {document?.title}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {document?.content}
                    </DialogContentText>
                </DialogContent>
            </BootstrapDialog>

            <footer className={styles.footer}>
                <Container maxWidth="lg">
                    <a href="https://lext.com.au" target="_blank" rel="noopener noreferrer">
                        Powered by{" "}
                        <span className={styles.logo}>
                            <img src="/lext-logo.png" alt="Lext Logo" width={60} height={25} />
                        </span>
                    </a>
                    <Typography
                        sx={{ mt: 2 }}
                        variant="body2"
                        color="text.secondary"
                        align="center"
                    >
                        Law off the Shelf ABN 96 634 540 864 is an online legal service that makes
                        it easier for Xero customers to generate legal documents based on both the
                        information stored in Xero and their own preferences. Law off the Shelf is
                        not a law firm and does not provide legal advice. The documents and
                        information published by Law off the Shelf are general and should not to be
                        considered legal advice. If you require legal advice, you should speak to a
                        lawyer. Use of Law off the Shelf is subject to our Terms and Conditions and
                        Privacy Policy.
                    </Typography>

                    <Stack sx={{ mt: 2, justifyContent: "center" }} direction="row" spacing={2}>
                        <Button onClick={handleClickOpen(termsOfService)}>Terms of Service</Button>
                        <Button onClick={handleClickOpen(privacyPolicy)}>Privacy Policy</Button>
                    </Stack>
                </Container>
            </footer>
        </Fragment>
    )
}

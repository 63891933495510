import { Organisation } from "xero-node"

const fees = {
    smallClaimsThreshold: 20000,
    filing: {
        corporate: {
            generalDivision: 618,
            smallClaimsDivision: 298,
        },
        individual: {
            generalDivision: 309,
            smallClaimsDivision: 149,
        },
    },
}

export const feesForEntityType = (entityType: Organisation["organisationEntityType"]) => {
    switch (entityType?.toString()) {
        case "INDIVIDUAL":
        case "PARTNERSHIP":
        case "SOLE_TRADER":
            return fees.filing.individual
        default:
            return fees.filing.corporate
    }
}

export default fees

// Login view

import { Avatar, Box, Button, CssBaseline, Grid, Paper } from "@mui/material"

import Footer from "./Footer"

import { useAuth } from "../context/AuthContext"

export default function Login() {
    const auth = useAuth()

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: "url(/lots-landing-page.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) =>
                        t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar sx={{ m: 2, bgcolor: "primary.main", width: 200, height: 200 }}>
                        <img
                            src="/lots-logo.png"
                            alt="LOTS Logo"
                            width={135}
                            height={40}
                            style={{ verticalAlign: "middle" }}
                        />
                    </Avatar>
                    <embed 
                        src="/LOTS_explainer.mp4"
                        width={400}
                        height={300}
                        style={{ verticalAlign: "middle" }}                    
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 6 }}
                        onClick={() => auth.signInWithXero()}
                    >
                        <b>Connect to Xero</b>
                    </Button>

                    <Footer />
                </Box>
            </Grid>
        </Grid>
    )
}

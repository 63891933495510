// React
import React, { useState } from "react"

// Material UI
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import AccountCircle from "@mui/icons-material/AccountCircle"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import { Stack } from "@mui/system"
import { styled } from "@mui/material/styles"

// Components
import OrganisationSelect from "./OrganisationSelect"

// Context
import { useAuth } from "../context/AuthContext"
import { Link, useLocation, useNavigate } from "react-router-dom"

const Pages = [
    { name: "Create new", path: "/" },
    { name: "My documents", path: "/my-documents" },
]

const Marker = styled("span")(({ theme }) => ({
    height: 3,
    width: 32,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 16px)",
    transition: theme.transitions.create("opacity"),
}))

export default function MenuBar() {
    const auth = useAuth()
    const location = useLocation()
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = async () => {
        await auth.signOut()
        handleClose()
        navigate("/login")
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar>
                <Toolbar>
                    <Box alignItems="baseline" sx={{ flexGrow: 1, display: { xs: "flex" } }}>
                        <span>
                            <img
                                src="/lots-logo.png"
                                alt="LOTS Logo"
                                width={135}
                                height={40}
                                style={{ verticalAlign: "middle" }}
                            />
                        </span>
                        {Pages.map((page) => (
                            <Button
                                key={page.name}
                                color="inherit"
                                component={Link}
                                to={page.path}
                                sx={{ pl: 2, pr: 2 }}
                            >
                                {page.name}
                                {location.pathname === page.path ? (
                                    <Marker className="Marker-root" />
                                ) : null}
                            </Button>
                        ))}
                    </Box>
                    <Box alignItems="baseline" sx={{ display: { xs: "flex" } }}>
                        {auth.activeTenant ? (
                            <div>
                                <Stack direction="row" alignItems="center" sx={{ pr: 2 }}>
                                    {auth.tenants.length > 0 ? (
                                        <OrganisationSelect />
                                    ) : (
                                        <Typography variant="h6">
                                            {auth.activeTenant.tenantName}
                                        </Typography>
                                    )}
                                    <Typography variant="h6" sx={{ pl: 2 }}>
                                        {auth.user?.name}
                                    </Typography>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => auth.signInWithXero()}>
                                            Connect another Organisation
                                        </MenuItem>
                                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                    </Menu>
                                </Stack>
                            </div>
                        ) : (
                            <Button color="inherit" onClick={() => auth.signInWithXero()}>
                                Login
                            </Button>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

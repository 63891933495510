// React
import { useState } from "react"

// Material UI
import { Box, ButtonGroup, Button } from "@mui/material"
import { NavigateBefore, NavigateNext } from "@mui/icons-material"

// React PDF
import { pdfjs, Document, Page } from "react-pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

type Props = {
    url: string
    width: number
}

const DocumentPreviewer = ({ url, width }: Props) => {
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)

    function onDocumentLoadSuccess({ numPages }: { numPages: any }): void {
        setNumPages(numPages)
        setPageNumber(1)
    }

    return (
        <Box
            component="span"
            sx={{
                p: 1,
                border: "1px dashed grey",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                "& > *": {
                    m: 1,
                },
            }}
        >
            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} width={width} />
            </Document>
            {(numPages ?? 1) > 1 && (
                <ButtonGroup size="small" variant="contained" aria-label="page controls group">
                    <Button
                        disabled={pageNumber <= 1}
                        onClick={() => setPageNumber(pageNumber - 1)}
                    >
                        <NavigateBefore />
                    </Button>

                    <Button
                        disabled={pageNumber >= (numPages || 0)}
                        onClick={() => setPageNumber(pageNumber + 1)}
                    >
                        <NavigateNext />
                    </Button>
                </ButtonGroup>
            )}
        </Box>
    )
}

export default DocumentPreviewer

// React
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"

// Components
import App from "./App"
import DocumentComplete from "./components/DocumentComplete"

// Contexts
import { AuthProvider } from "./context/AuthContext"

// Styles
import "./styles/globals.css"
import { createTheme, ThemeProvider } from "@mui/material/styles"

// Utils
import { SnackbarProvider } from "notistack"

declare module "notistack" {
    // eslint-disable-next-line no-unused-vars
    interface VariantOverrides {
        documentComplete: true
    }
}

const theme = createTheme({
    typography: {
        button: {
            textTransform: "none",
            fontSize: "1rem",
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
    <AuthProvider>
        <SnackbarProvider
            maxSnack={4}
            Components={{
                documentComplete: DocumentComplete,
            }}
        >
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </SnackbarProvider>
    </AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

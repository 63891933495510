import { DocumentType } from './Document'

export enum TemplateVariableSource {
    XeroInvoiceAndOrgData,
    XeroOrgData,
    XeroInvoice,
    XeroContact,
    XeroUser,
    XeroBankAccount,
    NSWCourtRegistry,
    StandaloneInput,
    DateNow,
    Static,
    eSignature,
}

export enum TemplateVariableInputType {
    DocumentReference, // A reference to a previously generated document
    PlainText, // Plain text
    Email, // An email address
    Percentage, // A percentage value e.g. 0 to 100
    Frequency, // A frequency of which something should recur e.g. daily, weekly, monthly, yearly
    Amount, // An amount of money
    DateInTheFuture, // A date in the future
    Deadline, // Number of days from now
    ACN, // Australian Company Number
    None, // No input support required
}

export type TemplateVariableMeta = {
    source: TemplateVariableSource
    inputType?: TemplateVariableInputType
    inputLabel?: string
    helperText?: string
    documentType?: DocumentType
    shouldUserBeAbleToEdit?: boolean // If true, the user will be able to edit/verify the value pulled from the source.
    isRequired: boolean
    defaultValue?: string
    evaluation?: (...args: any) => string
}

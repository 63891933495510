// Format iso date string to dd/mm/yyyy
export const formatDateString = (date?: string) => {
    if (!date) return null
    const d = new Date(date)
    const day = d.getDate()
    const month = d.getMonth() + 1
    const year = d.getFullYear()
    return `${day}/${month}/${year}`
}

// Format date to dd/mm/yyyy
export const formatDate = (date?: Date) => {
    if (!date) return null
    const d = new Date(date)
    const day = d.getDate()
    const month = d.getMonth() + 1
    const year = d.getFullYear()
    return `${day}/${month}/${year}`
}

// Number of days between two dates
export const daysBetween = (date1: Date, date2: Date) => {
    const diff = date1.getTime() - date2.getTime()
    return Math.ceil(diff / (1000 * 3600 * 24))
}

// Material UI
import { styled, Tooltip, tooltipClasses, Stack, TooltipProps, Button } from "@mui/material"

// Types
import { FAQ } from "../types/Template"

interface TemplateFAQsProps {
    faqs: FAQ[]
}

const TemplateFAQs = ({ faqs }: TemplateFAQsProps) => {
    const CustomButton = styled(Button)(({ theme }) => ({
        color: theme.palette.primary.main,
        textTransform: "none",
        fontWeight: 600,
        fontSize: "0.9rem",
        textAlign: "left",
        "&:hover": {
            backgroundColor: "transparent",
        },
    }))

    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 400,
            fontSize: "1.0rem",
        },
    })

    return (
        <Stack spacing={2}>
            {faqs.map((faq) => (
                <div
                    key={faq.question + faq.answer}
                >
                    <CustomWidthTooltip title={faq.answer} arrow placement="right">
                        <CustomButton sx={{ m: 1 }}>{faq.question}</CustomButton>
                    </CustomWidthTooltip>
                </div>
            ))}
        </Stack>
    )
}

export default TemplateFAQs

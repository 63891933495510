// React
import { useState } from "react"

// Material UI
import { Button, Grid, Typography } from "@mui/material"
import { Box, Container, Stack } from "@mui/system"

// Components
import OnlyNSWChip from "./OnlyNSWChip"
import ComingSoonChip from "./ComingSoonChip"
import CreateDocumentFormDialog from "./CreateDocumentFormDialog"
import MenuBar from "./MenuBar"
import Footer from "./Footer"

// Styles
import styles from "../styles/Home.module.css"

// Types
import { Template } from "../types/Template"

// Data
import { templateGroups } from "../data/TemplateGroups"
import DismissibleDisclaimer from "./DismissibleDisclaimer"
import DismissibleXeroDataWarning from "./DismissibleXeroDataWarning"

const CreateNew = () => {
    const [openFormDialog, setOpenFormDialog] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null)
    const handleOpenFormDialog = () => setOpenFormDialog(true)
    const handleCloseFormDialog = () => setOpenFormDialog(false)

    const handleTemplateSelection = (template: Template) => {
        console.log(template)
        setSelectedTemplate(template)
        handleOpenFormDialog()
    }

    return (
        <div className={styles.container}>
            <MenuBar />
            <CreateDocumentFormDialog
                template={selectedTemplate}
                open={openFormDialog}
                onClose={handleCloseFormDialog}
            />

            <Container sx={{ pt: 12 }} maxWidth="lg">
                <Box sx={{ minHeight: "100vh" }}>
                    <Typography variant="h3">Create new</Typography>

                    <DismissibleDisclaimer />
                    <DismissibleXeroDataWarning />

                    {templateGroups.map((group) => (
                        <Stack key={group.name} sx={{ pt: 4, pb: 2 }} spacing={2}>
                            <div>
                                <Typography variant="h5">{group.name}</Typography>
                            </div>
                            <div>
                                <Grid
                                    container
                                    rowSpacing={4}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    {group.templates.map((template) => (
                                        <Grid key={template.templateId} item xs={6}>
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Button
                                                    variant="contained"
                                                    disabled={template.isComingSoon}
                                                    color="primary"
                                                    onClick={() =>
                                                        handleTemplateSelection(template)
                                                    }
                                                >
                                                    {template.name}
                                                </Button>
                                                {template.isNSWOnly && <OnlyNSWChip />}
                                                {template.isComingSoon && <ComingSoonChip />}
                                            </Stack>
                                            <Typography sx={{ pt: 2 }} variant="body1">
                                                {template.summary}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </Stack>
                    ))}
                </Box>
            </Container>
            <Footer />
        </div>
    )
}

export default CreateNew

import {
    TemplateVariableSource,
    TemplateVariableInputType,
    TemplateVariableMeta,
} from "../types/TemplateVariableMeta"
import fees, { feesForEntityType } from "./Fees"

import { formatMoneyString, moneyFormatter } from "../lib/MoneyUtils"

import { Invoice, Organisation } from "xero-node"

import { DocumentType } from "../types/Document"

// TODO: Support for being one of multiple sources i.e. XeroInvoice or XeroContact. In the case of settlement agreement, there's a clause that references an invoices. This clause is optional and should only be included if the user has selected an invoice. This means that the source of the invoice number is either the invoice or a contact.

// TODO: Consider adding a main source option against each template.

type TemplateData = {
    variables: string[]
    variableMetaMap: { [key: string]: TemplateVariableMeta }
}

export const templateFieldData: { [key: string]: TemplateData } = {
    "letter-of-demand": {
        // Letter of Demand
        variables: [
            "{{LetterDate}}",
            "{{CreditorName}}",
            "{{CreditorUserName}}",
            "{{CreditorAddLine1}}",
            "{{CreditorAddLine2}}",
            "{{CreditorAddSuburb}}",
            "{{CreditorAddState}}",
            "{{CreditorAddPostcode}}",
            "{{DebtorName}}",
            "{{DebtorAddLine1}}",
            "{{DebtorAddLine2}}",
            "{{DebtorAddSuburb}}",
            "{{DebtorAddState}}",
            "{{DebtorAddPostcode}}",
            "{{DocType}}",
            "{{DocNo}}",
            "{{DocDate}}",
            "{{DebtAmount}}",
            "{{DebtDate}}",
            "{{CreditorAccName}}",
            "{{CreditorBSB}}",
            "{{CreditorAccNo}}",
            "{{CreditorBankRef}}",
            "{{Deadline}}",
            "{{CreditorEmail}}",
        ],
        variableMetaMap: {
            "{{LetterDate}}": {
                source: TemplateVariableSource.DateNow,
                isRequired: true,
            },
            "{{CreditorName}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorUserName}}": {
                source: TemplateVariableSource.XeroUser,
                isRequired: true,
            },
            "{{CreditorAddLine1}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddLine2}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddSuburb}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddState}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddPostcode}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{DebtorName}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddLine1}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddLine2}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddSuburb}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddState}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddPostcode}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DocType}}": {
                source: TemplateVariableSource.Static,
                isRequired: true,
                defaultValue: "Invoice",
            },
            "{{DocNo}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DocDate}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtAmount}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtDate}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{CreditorAccName}}": {
                source: TemplateVariableSource.XeroBankAccount,
                isRequired: true,
            },
            "{{CreditorBSB}}": {
                source: TemplateVariableSource.XeroBankAccount,
                isRequired: true,
            },
            "{{CreditorAccNo}}": {
                source: TemplateVariableSource.XeroBankAccount,
                isRequired: true,
            },
            "{{CreditorBankRef}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Bank reference",
                isRequired: false,
                evaluation: (value: any) => {
                    if (!value) {
                        return `The invoice number above`
                    } else {
                        return value
                    }
                }

            },
            "{{Deadline}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Deadline,
                inputLabel: "How long will they have to pay?",
                isRequired: true,
            },
            "{{CreditorEmail}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Email,
                inputLabel: "Your email address",
                isRequired: true,
            },
        },
    },
    "pre-litigation-letter-of-demand": {
        // Pre-litigation Letter of Demand
        variables: [
            "{{LetterDate}}",
            "{{CreditorName}}",
            "{{CreditorUserName}}",
            "{{CreditorAddLine1}}",
            "{{CreditorAddLine2}}",
            "{{CreditorAddSuburb}}",
            "{{CreditorAddState}}",
            "{{CreditorAddPostcode}}",
            "{{DebtorName}}",
            "{{DebtorAddLine1}}",
            "{{DebtorAddLine2}}",
            "{{DebtorAddSuburb}}",
            "{{DebtorAddState}}",
            "{{DebtorAddPostcode}}",
            "{{DocType}}",
            "{{DocNo}}",
            "{{DocDate}}",
            "{{DebtAmount}}",
            "{{CreditorAccName}}",
            "{{CreditorBSB}}",
            "{{CreditorAccNo}}",
            "{{CreditorBankRef}}",
            "{{Deadline}}",
            "{{CreditorEmail}}",
            "{{FirstLetterofDemandDocDate}}",
            "{{NumberOfDaysOverdue}}",
        ],
        variableMetaMap: {
            "{{LetterDate}}": {
                source: TemplateVariableSource.DateNow,
                isRequired: true,
            },
            "{{CreditorName}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorUserName}}": {
                source: TemplateVariableSource.XeroUser,
                isRequired: true,
            },
            "{{CreditorAddLine1}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddLine2}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddSuburb}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddState}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddPostcode}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{DebtorName}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddLine1}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddLine2}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddSuburb}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddState}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddPostcode}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DocType}}": {
                source: TemplateVariableSource.Static,
                isRequired: true,
                defaultValue: "Invoice",
            },
            "{{DocNo}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DocDate}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtAmount}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtDate}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{CreditorAccName}}": {
                source: TemplateVariableSource.XeroBankAccount,
                inputLabel: "Account name",
                isRequired: true,
            },
            "{{CreditorBSB}}": {
                source: TemplateVariableSource.XeroBankAccount,
                inputLabel: "BSB",
                isRequired: true,
            },
            "{{CreditorAccNo}}": {
                source: TemplateVariableSource.XeroBankAccount,
                inputLabel: "Account number",
                isRequired: true,
            },
            "{{CreditorBankRef}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Bank reference",
                isRequired: false,
                evaluation: (value: any) => {
                    if (!value) {
                        return `The invoice number above`
                    } else {
                        return value
                    }
                }
                
            },
            "{{Deadline}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Deadline,
                inputLabel: "How long will they have to pay?",
                isRequired: true,
            },
            "{{CreditorEmail}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Email,
                inputLabel: "Your email address",
                shouldUserBeAbleToEdit: true,
                isRequired: true,
            },
            "{{FirstLetterofDemandDocDate}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.DocumentReference,
                inputLabel: "Reference to the first letter of demand",
                documentType: DocumentType.LetterOfDemand,
                isRequired: true,
            },
            "{{NumberOfDaysOverdue}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
        },
    },
    "statement-of-claim": {
        // Statement of Claim
        variables: [
            "{General/Small Claims Division}",
            "{{RegistryName}}",
            "{{CreditorName}}",
            "{{CreditorACN}}",
            "{{DebtorName}}",
            "{{DebtorACN}}",
            "{{CreditorUserName}}",
            "{{CreditorUserTelephone}}",
            "{{CreditorUserEmail}}",
            "{{AmountOwing}}",
            "{{FilingFee}}",
            "{{AmountOwingPlusFilingFeeTotal}}",
            "{{UserDescriptionOfGoodAndServices}}",
            "{{InvoiceDate}}",
            "{{InvoiceDeadline}}",
            "{{DocType}}",
            "{{DocNo}}",
            "{{DocDate}}",
            "{{LetterOfDemandDocumentReference}}",
            "{{PreLitigationLetterOfDemandDocumentReference}}",
            "{{Date.Now}}",
            "{{RegistryStreetAddress}}",
            "{{RegistryPostalAddress}}",
            "{{RegistryPhone}}",
            "{{CreditorAddLine1}}",
            "{{CreditorAddLine2}}",
            "{{CreditorAddSuburb}}",
            "{{CreditorAddState}}",
            "{{CreditorAddPostcode}}",
            "{{DebtorAddLine1}}",
            "{{DebtorAddLine2}}",
            "{{DebtorAddSuburb}}",
            "{{DebtorAddState}}",
            "{{DebtorAddPostcode}}",
        ],
        variableMetaMap: {
            "{General/Small Claims Division}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{RegistryName}}": {
                source: TemplateVariableSource.NSWCourtRegistry,
                isRequired: true,
            },
            "{{CreditorName}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorACN}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: false,
            },
            "{{DebtorName}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorACN}}": {
                source: TemplateVariableSource.XeroInvoice,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Debtor ACN or ABN",
                shouldUserBeAbleToEdit: true,
                isRequired: false,
            },
            "{{CreditorUserName}}": {
                source: TemplateVariableSource.XeroUser,
                isRequired: true,
            },
            "{{CreditorUserTelephone}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorUserEmail}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Email,
                inputLabel: "Creditor email address",
                isRequired: true,
            },
            "{{AmountOwing}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{FilingFee}}": {
                source: TemplateVariableSource.XeroInvoiceAndOrgData,
                isRequired: true,
                evaluation: (i: Invoice, o: Organisation) => {
                    const amountDue = i.amountDue ? i.amountDue : 0
                    const fillingFee = feesForEntityType(
                        o.organisationEntityType,
                    )
                    if (amountDue > fees.smallClaimsThreshold) {
                        return moneyFormatter.format(fillingFee.generalDivision)
                    } else {
                        return moneyFormatter.format(fillingFee.smallClaimsDivision)
                    }
                },
            },
            "{{AmountOwingPlusFilingFeeTotal}}": {
                source: TemplateVariableSource.XeroInvoiceAndOrgData,
                isRequired: true,
                evaluation: (i: Invoice, o: Organisation) => {
                    const amountDue = i.amountDue ? i.amountDue : 0
                    const fillingFee = feesForEntityType(
                        o.organisationEntityType,
                    )
                    if (amountDue > fees.smallClaimsThreshold) {
                        return moneyFormatter.format(amountDue + fillingFee.generalDivision)
                    } else {
                        return moneyFormatter.format(amountDue + fillingFee.smallClaimsDivision)
                    }
                },
            },
            "{{UserDescriptionOfGoodAndServices}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Description of goods and services",
                helperText: "E.g. 'Search engine optimisation services', or 'pet food'",
                isRequired: true,
            },
            "{{InvoiceDate}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{InvoiceDeadline}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DocType}}": {
                source: TemplateVariableSource.Static,
                isRequired: true,
                defaultValue: "Invoice",
            },
            "{{DocNo}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DocDate}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{LetterOfDemandDocumentReference}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.DocumentReference,
                inputLabel: "First letter of demand",
                documentType: DocumentType.LetterOfDemand,
                isRequired: true,
            },
            "{{PreLitigationLetterOfDemandDocumentReference}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.DocumentReference,
                inputLabel: "Pre-litigation letter of demand",
                documentType: DocumentType.PreLitigationLetterOfDemand,
                isRequired: false,
            },
            "{{Date.Now}}": {
                source: TemplateVariableSource.DateNow,
                isRequired: true,
            },
            "{{RegistryStreetAddress}}": {
                source: TemplateVariableSource.NSWCourtRegistry,
                isRequired: true,
            },
            "{{RegistryPostalAddress}}": {
                source: TemplateVariableSource.NSWCourtRegistry,
                isRequired: true,
            },
            "{{RegistryPhone}}": {
                source: TemplateVariableSource.NSWCourtRegistry,
                isRequired: true,
            },
            "{{CreditorAddLine1}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddLine2}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddSuburb}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddState}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddPostcode}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{DebtorAddLine1}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddLine2}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddSuburb}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddState}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddPostcode}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
        },
    },
    "settlement-and-release-agreement": {
        // Settlement and release agreement
        variables: [
            "{{CreditorName}}",
            "{{CreditorACN}}",
            "{{DebtorName}}",
            "{{DebtorACN}}",
            "{{ExecutionDate}}",
            "{{CreditorAddLine1}}",
            "{{CreditorAddLine2}}",
            "{{CreditorAddSuburb}}",
            "{{CreditorAddState}}",
            "{{CreditorAddPostcode}}",
            "{{DebtorAddLine1}}",
            "{{DebtorAddLine2}}",
            "{{DebtorAddSuburb}}",
            "{{DebtorAddState}}",
            "{{DebtorAddPostcode}}",
            "{{CreditorAccName}}",
            "{{CreditorBSB}}",
            "{{CreditorAccNumber}}",
            "{{CreditorBankRef}}",
            "{{SettlementAmount}}",
            "{{DocType}}",
            "{{DocNo}}",
            "{{DocDate}}",
            "{{SettlementPaymentDays}}",
            "{{CreditorUserSignature}}",
            "{{CreditorUserName}}",
            "{{DebtorUserSignature}}",
            "{{DebtorUserName}}",
        ],
        variableMetaMap: {
            "{{CreditorName}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorACN}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: false,
            },
            "{{DebtorName}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorACN}}": {
                source: TemplateVariableSource.XeroInvoice,
                inputType: TemplateVariableInputType.PlainText,
                shouldUserBeAbleToEdit: true,
                isRequired: false,
            },
            "{{ExecutionDate}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{CreditorAddLine1}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddLine2}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddSuburb}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddState}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddPostcode}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{DebtorAddLine1}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddLine2}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddSuburb}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddState}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{DebtorAddPostcode}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
            "{{CreditorAccName}}": {
                source: TemplateVariableSource.XeroBankAccount,
                inputLabel: "Account name",
                isRequired: true,
            },
            "{{CreditorBSB}}": {
                source: TemplateVariableSource.XeroBankAccount,
                inputLabel: "BSB",
                isRequired: true,
            },
            "{{CreditorAccNumber}}": {
                source: TemplateVariableSource.XeroBankAccount,
                inputLabel: "Account number",
                isRequired: true,
            },
            "{{CreditorBankRef}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Bank reference",
                isRequired: false,
                evaluation: (value: any) => {
                    if (!value) {
                        return `The invoice number above`
                    } else {
                        return value
                    }
                }
            },
            "{{SettlementAmount}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Amount,
                inputLabel: "Settlement amount",
                isRequired: true,
                evaluation: (value: string) => {
                    return value ? formatMoneyString(value) : ""
                }
            },
            "{{DocType}}": {
                source: TemplateVariableSource.Static,
                isRequired: false,
                defaultValue: "Invoice",
            },
            "{{DocNo}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: false,
            },
            "{{DocDate}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: false,
            },
            "{{SettlementPaymentDays}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Deadline,
                inputLabel: "Settlement payment days",
                isRequired: true,
            },
            "{{CreditorUserSignature}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{CreditorUserName}}": {
                source: TemplateVariableSource.XeroUser,
                isRequired: true,
            },
            "{{DebtorUserSignature}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{DebtorUserName}}": {
                source: TemplateVariableSource.XeroInvoice,
                isRequired: true,
            },
        },
    },
    "loan-agreement": {
        // Loan agreement
        variables: [
            "{{CreditorName}}",
            "{{CreditorACN}}",
            "{{DebtorName}}",
            "{{DebtorACN}}",
            "{{ExecutionDate}}",
            "{{CreditorAddLine1}}",
            "{{CreditorAddLine2}}",
            "{{CreditorAddSuburb}}",
            "{{CreditorAddState}}",
            "{{CreditorAddPostcode}}",
            "{{DebtorAddLine1}}",
            "{{DebtorAddLine2}}",
            "{{DebtorAddSuburb}}",
            "{{DebtorAddState}}",
            "{{DebtorAddPostcode}}",
            "{{LoanAmount}}",
            "{{LoanInterestRate}}",
            "{{DebtorAccName}}",
            "{{DebtorBSB}}",
            "{{DebtorAccNumber}}",
            "{{Repayment Date}}",
            "{{InterestRepaymentDate}}",
            "{{CreditorEmail}}",
            "{{BorrowerEmail}}",
            "{{CreditorUserSignature}}",
            "{{CreditorUserName}}",
            "{{DebtorUserSignature}}",
            "{{DebtorUserName}}"
        ],
        variableMetaMap: {
            "{{CreditorName}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorACN}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: false,
            },
            "{{DebtorName}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorACN}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: false,
            },
            "{{ExecutionDate}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{CreditorAddLine1}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddLine2}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddSuburb}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddState}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddPostcode}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{DebtorAddLine1}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddLine2}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddSuburb}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddState}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddPostcode}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{LoanAmount}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Amount,
                inputLabel: "Loan amount",
                isRequired: true,
                evaluation: (value: string) => {
                    return value ? formatMoneyString(value) : ""
                }
            },
            "{{LoanInterestRate}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Percentage,
                inputLabel: "Interest rate per annum",
                isRequired: false,
            },
            "{{DebtorAccName}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Borrower account name",
                isRequired: true,
            },
            "{{DebtorBSB}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Borrower BSB",
                isRequired: true,
            },
            "{{DebtorAccNumber}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Borrower account number",
                isRequired: true,
            },
            "{{Repayment Date}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Repayment date",
                helperText: "Enter a date in the format DD/MM/YYYY",
                isRequired: true,
            },
            "{{InterestRepaymentDate}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Interest repayment date",
                helperText: "Enter a date in the format DD/MM/YYYY",
                isRequired: true,
            },
            "{{CreditorEmail}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Email,
                inputLabel: "Lender email",
                isRequired: true,
            },
            "{{BorrowerEmail}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{CreditorUserSignature}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{CreditorUserName}}": {
                source: TemplateVariableSource.XeroUser,
                isRequired: true,
            },
            "{{DebtorUserSignature}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{DebtorUserName}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
        },
    },
    "loan-agreement-with-security": {
        // Division 7A Loan Agreement
        variables: [
            "{{ExecutionDate}}",
            "{{CreditorName}}",
            "{{CreditorACN}}",
            "{{CreditorAddLine1}}",
            "{{CreditorAddLine2}}",
            "{{CreditorAddSuburb}}",
            "{{CreditorAddState}}",
            "{{CreditorAddPostcode}}",
            "{{DebtorName}}",
            "{{DebtorACN}}",
            "{{DebtorAddLine1}}",
            "{{DebtorAddLine2}}",
            "{{DebtorAddSuburb}}",
            "{{DebtorAddState}}",
            "{{DebtorAddPostcode}}",
            "{{CreditorUserSignature}}",
            "{{CreditorUserName}}",
            "{{DebtorUserSignature}}",
            "{{DebtorUserName}}"
        ],
        variableMetaMap: {
            "{{ExecutionDate}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{CreditorName}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorACN}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: false,
            },
            "{{CreditorAddLine1}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddLine2}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddSuburb}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddState}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddPostcode}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{DebtorName}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorACN}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: false,
            },
            "{{DebtorAddLine1}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddLine2}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddSuburb}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddState}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddPostcode}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{CreditorUserSignature}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{CreditorUserName}}": {
                source: TemplateVariableSource.XeroUser,
                isRequired: true,
            },
            "{{DebtorUserSignature}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{DebtorUserName}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
        },
    },
    "promissory-note": {
        // Promissory Note
        variables: [
            "{{CreditorName}}",
            "{{CreditorACN}}",
            "{{CreditorAddLine1}}",
            "{{CreditorAddLine2}}",
            "{{CreditorAddSuburb}}",
            "{{CreditorAddState}}",
            "{{CreditorAddPostcode}}",
            "{{DebtorName}}",
            "{{DebtorACN}}",
            "{{DebtorAddLine1}}",
            "{{DebtorAddLine2}}",
            "{{DebtorAddSuburb}}",
            "{{DebtorAddState}}",
            "{{DebtorAddPostcode}}",
            "{{LoanAmount}}",
            "{{LoanInterestRate}}",
            "{{ExecutionDate}}",
            "{{PaymentEvent/RepaymentDate}}",
            "{{CreditorUserSignature}}",
            "{{CreditorUserName}}",
            "{{DebtorUserSignature}}",
            "{{DebtorUserName}}"
        ],
        variableMetaMap: {
            "{{CreditorName}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorACN}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: false,
            },
            "{{CreditorAddLine1}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddLine2}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddSuburb}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddState}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{CreditorAddPostcode}}": {
                source: TemplateVariableSource.XeroOrgData,
                isRequired: true,
            },
            "{{DebtorName}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorACN}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: false,
            },
            "{{DebtorAddLine1}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddLine2}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddSuburb}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddState}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{DebtorAddPostcode}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
            "{{LoanAmount}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Amount,
                inputLabel: "Amount",
                isRequired: true,
                evaluation: (value: string) => {
                    return value ? formatMoneyString(value) : ""
                }
            },
            "{{LoanInterestRate}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.Percentage,
                inputLabel: "Interest rate per annum",
                isRequired: false,
                evaluation: (value: string) => {
                    return value ? `plus interest at the rate of ${value}% per annum calculated from the Date of Issue to the date the amount is repaid in full.` : "";
                }
            },
            "{{ExecutionDate}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{PaymentEvent/RepaymentDate}}": {
                source: TemplateVariableSource.StandaloneInput,
                inputType: TemplateVariableInputType.PlainText,
                inputLabel: "Repayment date",
                helperText: "Enter a repayment date in the format DD/MM/YYYY",
                isRequired: true,
            },
            "{{CreditorUserSignature}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{CreditorUserName}}": {
                source: TemplateVariableSource.XeroUser,
                isRequired: true,
            },
            "{{DebtorUserSignature}}": {
                source: TemplateVariableSource.eSignature,
                isRequired: true,
            },
            "{{DebtorUserName}}": {
                source: TemplateVariableSource.XeroContact,
                isRequired: true,
            },
        },
    },
}

// React
import { useState, useEffect } from "react"

// Material UI
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { FormHelperText } from "@mui/material"

// Contexts
import { useAuth } from "../context/AuthContext"

// Types
import { Account } from "xero-node"

const API_KEY = process.env.REACT_APP_API_KEY

interface Props {
    defaultAccountID: string | null
    // eslint-disable-next-line no-unused-vars
    onSelect: (account: Account | null) => void
    isRequired: boolean
}

const BankAccountSelect = ({ defaultAccountID, onSelect, isRequired }: Props) => {
    const auth = useAuth()
    const [loading, setLoading] = useState(true)
    const [accounts, setAccounts] = useState<Account[]>([])

    const handleChange = (event: SelectChangeEvent) => {
        onSelect(accounts.find((a) => a.accountID === event.target.value) || null)
    }

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            const resp = await fetch("/api/accounts/banks", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Api-Key ${API_KEY}`,
                },
            })
            if (resp.status === 200) {
                const response = await resp.json()
                setAccounts(response.accounts)
            } else {
                setAccounts([])
            }
            setLoading(false)
        })()
    }, [auth.loading, auth.activeTenant])

    // Format account number to separate the bsb from the account number.
    const formatAccountNumber = (accountNumber?: string) => {
        if (accountNumber) {
            return (
                " - " +
                accountNumber.substring(0, 3) +
                "-" +
                accountNumber.substring(3, 6) +
                " " +
                accountNumber.substring(6)
            )
        }
        return ""
    }

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="bank-account-select-label" required={isRequired}>
                    Bank account
                </InputLabel>
                <Select
                    labelId="bank-account-select-label"
                    id="bank-account-select"
                    label="Bank account"
                    defaultValue={defaultAccountID || ""}
                    onChange={handleChange}
                >
                    {loading ? (
                        <MenuItem value="0">Loading...</MenuItem>
                    ) : accounts.length > 0 ? (
                        accounts.map((i) => (
                            <MenuItem key={i.accountID} value={i.accountID}>
                                {i.name}
                                {formatAccountNumber(i.bankAccountNumber)}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="0">No bank accounts found</MenuItem>
                    )}
                </Select>
                <FormHelperText>Select the payee bank account</FormHelperText>
            </FormControl>
        </Box>
    )
}

export default BankAccountSelect
